﻿import SlideUpDown from '../base/SlideUpDown';

export default {
    name: 'Panel',
    directives: { SlideUpDown },
    props: {
        title: String,
        iconCls: String,
        border: {
            type: Boolean,
            default: true
        },
        animate: {
            type: Boolean,
            default: false
        },
        closed: {
            type: Boolean,
            default: false
        },
        collapsed: {
            type: Boolean,
            default: false
        },
        collapsible: {
            type: Boolean,
            default: false
        },
        closable: {
            type: Boolean,
            default: false
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        showFooter: {
            type: Boolean,
            default: true
        },
        expandIconCls: {
            type: String,
            default: 'panel-tool-expand'
        },
        collapseIconCls: {
            type: String,
            default: 'panel-tool-collapse'
        },
        closeIconCls: {
            type: String,
            default: 'panel-tool-close'
        },
        panelCls: String,
        panelStyle: Object,
        headerCls: String,
        headerStyle: Object,
        bodyCls: String,
        bodyStyle: Object,
        footerCls: String,
        footerStyle: Object
    },
    data() {
        return {
            collapsedState: this.collapsed,
            closedState: this.closed,
            animateState: this.animate,
            collapseToShrinkBody: true
        }
    },
    computed: {
        hasHeader() {
            if (!this.showHeader) {
                return false;
            }
            if (this.$slots['header'] || this.title) {
                return true;
            } else {
                return false;
            }
        },
        hasFooter() {
            if (!this.showFooter) {
                return false;
            }
            if (this.$slots['footer']) {
                return true;
            } else {
                return false;
            }
        },
        panelClasses() {
            return ['panel f-column', this.panelCls];
        },
        headerClasses() {
            return ['panel-header f-noshrink f-row f-vcenter', this.headerCls,
                { 'panel-header-noborder': !this.border }
            ];
        },
        bodyClasses() {
            return ['panel-body f-full', this.bodyCls, {
                'panel-body-noheader': !this.hasHeader,
                'panel-body-nobottom': this.$slots['footer'],
                'panel-body-noborder': !this.border
            }];
        },
        footerClasses() {
            return ['panel-footer f-noshrink', this.footerCls, {
                'panel-footer-noborder': !this.border
            }];
        },
        collapsibleClasses() {
            return this.collapsedState ? this.expandIconCls : this.collapseIconCls;
        },
        closableClasses() {
            return this.closeIconCls;
        }
    },
    watch: {
        closed(value) {
            this.closedState = value;
        },
        collapsed(value) {
            this.collapsedState = value;
        },
        animate(value) {
            this.animateState = value;
        }
    },
    methods: {
        clickCollapsibleTool() {
            this.collapsedState = !this.collapsedState;
        },
        clickCloseTool() {
            this.closedState = true;
        },
        panelHeader() {
            if (!this.hasHeader) {
                return;
            }
            const headerTitle = () => {
                if (this.$slots['header']) {
                    return this.$slots['header']()
                } else {
                    const cls = ['panel-title'];
                    if (this.iconCls && !this.$slots['icon']) {
                        cls.push('panel-with-icon');
                    }
                    return (
                        <div class={cls}>{this.title}</div>
                    )
                }
            }
            const headerIcon = () => {
                if (this.iconCls && !this.$slots['icon']) {
                    return <div class={this.iconCls + ' panel-icon'}></div>
                } else {
                    return null;
                }
            }
            const panelTools = () => {
                if (this.collapsible || this.closable) {
                    return (
                        <div class="panel-tool">
                            {this.collapsible && <a href="javascript:;" class={this.collapsibleClasses} onClick={this.clickCollapsibleTool}></a>}
                            {this.closable && <a href="javascript:;" class={this.closableClasses} onClick={this.clickCloseTool}></a>}
                        </div>
                    )
                }
            }

            return (
                <div ref="headerRef" class={this.headerClasses} style={this.headerStyle}>
                    {headerTitle()}
                    {headerIcon()}
                    {panelTools()}
                </div>
            )
        },
        panelBody() {
            return (
                <div ref="bodyRef" v-SlideUpDown={{ animate: this.animateState, collapsed: this.collapsedState, disabled: !this.collapseToShrinkBody }} class={this.bodyClasses} style={this.bodyStyle}>
                    {this.$slots.default && this.$slots.default()}
                </div>
            )
        },
        panelFooter() {
            if (!this.hasFooter) {
                return null;
            }
            return (
                <div ref="footerRef" class={this.footerClasses} style={this.footerStyle}>
                    {this.$slots['footer'] && this.$slots['footer']()}
                </div>
            )
        }
    },
    render() {
        if (!this.closedState) {
            return (
                <div class={this.panelClasses} style={this.panelStyle}>
                    {this.panelHeader()}
                    {this.panelBody()}
                    {this.panelFooter()}
                </div>
            )
        } else {
            return null;
        }
    }
}