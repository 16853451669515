﻿import PaginationButton from './PaginationButton';
import PaginationList from './PaginationList';
import PaginationLink from './PaginationLink';
import PaginationManual from './PaginationManual';

export default {
    name: 'Pagination',
    components: {
        PaginationButton,
        PaginationList,
        PaginationLink,
        PaginationManual
    },
    props: {
        pageList: {
            type: Array,
            default: () => [10, 20, 30, 40, 50]
        },
        loading: {
            type: Boolean,
            default: false
        },
        showPageList: {
            type: Boolean,
            default: true
        },
        showPageInfo: {
            type: Boolean,
            default: true
        },
        showPageRefresh: {
            type: Boolean,
            default: true
        },
        links: {
            type: Number,
            default: 10
        },
        beforePageText: {
            type: String,
            default: () => window.Locale.t('Pagination.beforePageText', 'Page')
        },
        afterPageText: {
            type: String,
            default: () => window.Locale.t('Pagination.afterPageText', 'of {pages}')
        },
        displayMsg: {
            type: String,
            default: () => window.Locale.t('Pagination.displayMsg', 'Displaying {from} to {to} of {total} items')
        },
        layout: {
            type: Array,
            default: () => ['first', 'prev', 'links', 'next', 'last', 'refresh']
        },
        total: {
            type: Number,
            default: 0
        },
        pageSize: {
            type: Number,
            default: 10
        },
        pageNumber: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            pageNumberState: this.pageNumber,
            pageSizeState: this.pageSize,
            lastState: null,
            pagination: this
        }
    },
    computed: {
        pageInfo() {
            let info = this.displayMsg;
            info = info.replace(/{from}/, String(this.total == 0 ? 0 : this.pageSizeState * (this.pageNumberState - 1) + 1));
            info = info.replace(/{to}/, String(Math.min(this.pageSizeState * (this.pageNumberState), this.total)));
            info = info.replace(/{total}/, String(this.total));
            return info;
        },
        pageCount() {
            return !this.total ? 0 : Math.ceil(this.total / this.pageSizeState) || 1;
        },
        afterPageInfo() {
            let info = this.afterPageText;
            info = info.replace(/{pages}/, String(this.pageCount));
            return info;
        }
    },
    created() {
        this._adjustPage();
        this.lastState = {
            pageNumber: this.pageNumberState,
            pageSize: this.pageSizeState
        };
    },
    watch: {
        pageNumber(value) {
            this.pageNumberState = value;
        },
        pageSize(value) {
            this.pageSizeState = value;
        },
        pageNumberState() {
            this._adjustPage();
        },
        pageSizeState() {
            this._adjustPage();
        },
        total() {
            this._adjustPage();
        }
    },
    methods: {
        _isButton(name) {
            let aa = ['first', 'prev', 'next', 'last', 'refresh'];
            let index = aa.indexOf(name);
            return index >= 0;
        },
        _adjustPage() {
            if (this.pageNumberState < 1) {
                this.pageNumberState = 1;
            }
            if (this.pageNumberState > this.pageCount) {
                this.pageNumberState = this.pageCount;
            }
            if (this.total == 0) {
                this.pageNumberState = 0;
                if (this.lastState) {
                    this.lastState.pageNumber = 1;
                }
            }
            // if (this.lastState){
            //     let state = {pageNumber: this.pageNumberState, pageSize: this.pageSizeState};
            //     if (this.pageNumberState != this.lastState.pageNumber || this.pageSizeState != this.lastState.pageSize){
            //         this.lastState = state;
            //         this.$emit('pageChange', this.lastState);
            //     }
            // }
            if (this.lastState) {
                let state = { pageNumber: this.pageNumberState || 1, pageSize: this.pageSizeState };
                if (state.pageNumber != this.lastState.pageNumber || state.pageSize != this.lastState.pageSize) {
                    this.lastState = state;
                    this.$emit('pageChange', this.lastState);
                }
            }
        },
        selectPage(page) {
            this.pageNumberState = page;
            this._adjustPage();
        },
        refreshPage() {
            let state = Object.assign({ refresh: true }, this.lastState);
            if (state.pageNumber <= 0) {
                state.pageNumber = 1;
            }
            this.$emit('pageChange', state);
        }
    },
    render() {
        return (
            <div class="pagination f-row f-content-center">
                {
                    this.layout.map(name => (
                        <>
                            {name == 'list' && <PaginationList></PaginationList>}
                            {name == 'links' && <PaginationLink></PaginationLink>}
                            {name == 'manual' && <PaginationManual pageNumber={this.pageNumberState} onPageInput={(page) => this.pageNumberState = page}></PaginationManual>}
                            {this._isButton(name) && <PaginationButton name={name}></PaginationButton>}
                            {name == 'sep' && <div class="pagination-btn-separator"></div>}
                            {
                                name == 'info' &&
                                <div class="f-full">
                                    <div class="pagination-info">{this.pageInfo}</div>
                                </div>
                            }
                            {
                                name == 'tpl' && this.$slots.default && this.$slots.default(this.pagination)
                            }
                        </>
                    ))
                }
            </div >
        )
    }
}