﻿import domHelper from '../base/DomHelper';
import Panel from '../panel/Panel';

export default {
    name: 'CollapsedPanel',
    extends: Panel,
    props: {
        region: String
    },
    computed: {
        layout(){
            return this.$parent;
        },
        visible(){
            const panel = this.layout.getPanel(this.region);
            if (!panel){
                return false;
            }
            if (!panel.collapsedState || !panel.expander){
                return false;
            }
            return true;
        },
        panelClasses(){
            return ['panel f-column layout-expand', this.panelCls, {
                'layout-expand-east': this.region=='east',
                'layout-expand-west': this.region=='west',
                'layout-expand-south': this.region=='south',
                'layout-expand-north': this.region=='north'
            }]
        },
        panelStyles(){
            const panel = this.layout.getPanel(this.region);
            if (!panel){
                return null;
            }
            return [this.panelStyle, {
                top: this.region==='west'||this.region==='east' ? this.layout.paddingTop+'px' : (this.region==='north' ? 0 : null),
                bottom: this.region==='west'||this.region==='east' ? this.layout.paddingBottom+'px' : (this.region==='south' ? 0 : null),
                left: this.region==='west'||this.region==='north'||this.region==='south' ? 0 : null,
                right: this.region==='east' ? 0 : null,
                width: this.region==='west'||this.region==='east' ? panel.collapsedSize+'px' : '100%',
                height: this.region==='north'||this.region==='south' ? panel.collapsedSize+'px' : null,
                position: 'absolute'
            }]
        }
    },
    methods: {
        onPanelClick(e){
            const ptool = domHelper.closest(e.target, '.panel-tool');
            if (!ptool){
                e.preventDefault();
                e.stopPropagation();
                this.expand();
            }
        },
        clickCollapsibleTool(){
            this.expand();
        },
        expand(){
            this.layout.getPanel(this.region).expand();
        }
    },
    render() {
        if (this.visible) {
            return (
                <div onClick={this.onPanelClick} class={this.panelClasses} style={this.panelStyles}>
                    {this.panelHeader()}
                    {this.panelBody()}
                    {this.panelFooter()}
                </div>
            )
        } else {
            return null;
        }
    }
}