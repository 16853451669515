﻿import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LinkButton',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        toggle: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Boolean,
            default: false
        },
        outline: {
            type: Boolean,
            default: false
        },
        plain: {
            type: Boolean,
            default: false
        },
        text: String,
        iconCls: String,
        iconAlign: {
            type: String,
            default: 'left'
        },
        size: {
            type: String,
            default: 'small'    // or large
        },
        href: String,
        btnCls: String,
        btnStyle: Object
    },
    data() {
        return {
            selectedState: this.selected,
            focused: false
        }
    },
    computed: {
        isEmpty() {
            return !this.text && !this.$slots.default;
        },
        isDisabled() {
            return this.disabled;
        },
        btnIconCls() {
            let cls = 'l-btn-icon';
            if (this.iconCls) {
                cls += ' ' + this.iconCls;
            }
            return cls;
        },
        innerCls() {
            let cls = 'l-btn f-inline-row f-content-center';
            cls += ' l-btn-' + this.size;
            if (this.plain) {
                cls += ' l-btn-plain';
            }
            if (this.outline) {
                cls += ' l-btn-outline';
            }
            if (this.selectedState) {
                cls += this.plain ? ' l-btn-selected l-btn-plain-selected' : ' l-btn-selected';
            }
            if (this.isDisabled) {
                cls += this.plain ? ' l-btn-disabled l-btn-plain-disabled' : ' l-btn-disabled';
            }
            if (this.focused) {
                cls += ' l-btn-focus';
            }
            if (this.btnCls) {
                cls += ' ' + this.btnCls;
            }
            if (this.$attrs.class) {
                cls += ' ' + this.$attrs.class;
            }
            return cls;
        },
        btnLeftCls() {
            let cls = 'l-btn-left';
            if (this.iconCls) {
                cls += ' l-btn-icon-' + this.iconAlign;
            }
            return cls;
        }
    },
    watch: {
        selected(value) {
            this.selectedState = value;
        }
    },
    mounted() {
        if (this.$parent && this.$parent.$options.name == 'ButtonGroup') {
            this.$parent.addButton(this);
        }
    },
    beforeUnmount() {
        if (this.$parent && this.$parent.$options.name == 'ButtonGroup') {
            this.$parent.removeButton(this);
        }
    },
    methods: {
        focus() {
            if (this.$refs.btnRef) {
                this.$refs.btnRef.focus();
            }
            this.focused = true;
        },
        blur() {
            if (this.$refs.btnRef) {
                this.$refs.btnRef.blur();
            }
            this.focused = false;
        },
        onClick(event) {
            event.stopPropagation();
            if (this.disabled) {
                event.preventDefault();
                return false;
            }
            if (!this.href) {
                event.preventDefault();
            }
            if (this.toggle) {
                this.selectedState = !this.selectedState;
            }
            // this.$emit('click');
            this.afterClick();
        },
        afterClick() {

        },
        renderInner() {
            let content = '';
            if (this.isEmpty) {
                content = '';
            } else if (this.text) {
                content = this.text;
            } else if (this.$slots.default) {
                content = this.$slots.default();
            }
            return (
                <>
                    <span class={this.isEmpty ? 'l-btn-text l-btn-empty' : 'l-btn-text'}>
                        {content}
                    </span>
                    <span class={this.btnIconCls}></span>
                </>
            )
        },
        renderOthers() {
            return null;
        }
    },

    render() {
        return (
            <a ref="btnRef"
                href={this.href || '#'}
                class={this.innerCls}
                style={Object.assign({}, this.$attrs.style, this.btnStyle)}
                onClick={this.onClick}
                onFocus={this.focus}
                onBlur={this.blur}
            >
                <span class={this.btnLeftCls}>
                    {this.renderInner()}
                </span >
                {this.renderOthers()}
            </a >
        )
    }
})