﻿import GridView from '../gridbase/GridView';
import TreeGridBody from './TreeGridBody'

export default {
    name: 'TreeGridView',
    extends: GridView,
    components: {
        TreeGridBody
    },
    computed: {
        grid() {
            return this.$parent;
        }
    },
    methods: {
        onHeaderCellClick(event) {
            let col = event.column;
            if (col.sortable) {
                this.grid.addSort(event.column);
                // this.grid.data = this.grid.data;
                this.grid.setData(this.grid.innerData);
                this.grid.$emit('sortChange', this.grid.sortsState);
            }
        }
    },
    render() {
        return (
            <div class={this.viewCls}>
                {
                    this.grid.showHeader &&
                    <GridHeader ref="header"
                        columnGroup={this.columnGroup}
                        columns={this.columns}
                        paddingWidth={this.headerPaddingWidth}
                        grid={this.grid}
                        onCellClick={this.onHeaderCellClick}>
                    </GridHeader>
                }
                <TreeGridBody ref="body" align="center"
                    columns={this.columns}
                    rows={this.rows}
                    onBodyScroll={this.onBodyScroll}>
                </TreeGridBody>
                {
                    this.grid.showFooter &&
                    <GridFooter ref="footer"
                        columns={this.columns}
                        rows={this.footerRows}
                        paddingWidth={this.headerPaddingWidth}>
                    </GridFooter>
                }
            </div>
        )
    }
}