﻿import LinkButton from '../linkbutton/LinkButton';

export default {
    name: 'PaginationLink',
    components: {
        LinkButton
    },
    computed: {
        pages() {
            let begin = this.$parent.pageNumberState - Math.floor(this.$parent.links / 2);
            if (begin < 1) {
                begin = 1;
            }
            let end = begin + this.$parent.links - 1;
            if (end > this.$parent.pageCount) {
                end = this.$parent.pageCount;
            }
            begin = end - this.$parent.links + 1;
            if (begin < 1) {
                begin = 1;
            }
            let pp = [];
            for (let i = begin; i <= end; i++) {
                pp.push(i);
            }
            return pp;
        }
    },
    methods: {
        onClick(page) {
            this.$parent.selectPage(page);
        }
    },
    render() {
        return (
            <div class="pagination-links f-inline-row">
                {
                    this.pages.map(page => (
                        <LinkButton
                            class="pagination-link"
                            selected={page == this.$parent.pageNumberState}
                            plain={true}
                            text={String(page)}
                            onClick={() => this.onClick(page)}
                        />
                    ))
                }
            </div>
        )
    }
}