﻿import domHelper from '../base/DomHelper';

export default {
    name: 'TabPanelHeader',
    props: {
        panel: Object
    },
    render() {
        let title = null;
        if (this.panel.$slots['header']) {
            title = this.panel.$slots['header']();
        } else {
            title = (
                <span class={['tabs-title', {
                    'tabs-with-icon': this.panel.iconCls,
                    'tabs-closable': this.panel.closable
                }]}>{this.panel.title}</span>
            )
        }
        let icon = null;
        if (this.panel.iconCls) {
            icon = <span class={['tabs-icon', this.panel.iconCls]}></span>
        }
        let closable = null;
        if (this.panel.closable) {
            closable = (
                <a href="javascript:;" tabindex="-1" class="tabs-close"
                    onClick={event => {
                        this.$emit('close', event)
                    }}
                />
            )
        }
        return (
            <span
                class={['tabs-inner f-inline-row f-full', this.panel.headerCls]}
                style={[this.panel.headerStyle, {
                    width: !this.$parent.isHorizontal ? domHelper.toStyleValue(this.$parent.tabWidth) : null,
                    height: this.$parent.isHorizontal ? domHelper.toStyleValue(this.$parent.tabHeight) : null
                }]}
                onContextmenu={event => {
                    this.panel.$emit('contextMenu', event)
                }}
            >
                {title}
                {icon}
                {closable}
            </span>
        )
    }
}