﻿import LinkButton from '../linkbutton/LinkButton';

export default {
    name: 'MenuButton',
    extends: LinkButton,
    props: {
        menuAlign: {
            type: String,
            default: 'left'
        },
        duration: {
            type: Number,
            default: 100
        }
    },
    data() {
        return {
            menu: null,
            timer: null
        }
    },
    computed: {
        isEmpty() {
            return !this.text && !this.$slots.text;
        },
        innerCls() {
            let cls = LinkButton.computed.innerCls.call(this);
            cls += ' m-btn m-btn-' + this.size;
            if (this.menu && !this.menu.closed) {
                cls += this.plain ? ' m-btn-plain-active' : ' m-btn-active';
            }
            return cls;
        }
    },
    mounted() {
        // this.$children.forEach(c => {
        //     if (c.$options.name == 'Menu') {
        //         this.menu = c;
        //     }
        // });
    },
    methods: {
        setMenu(menu) {
            this.menu = menu;
        },
        onClick(event) {
            LinkButton.methods.onClick.call(this, event);
            this.showMenu();
        },
        onMouseEnter() {
            if (this.disabled) {
                return;
            }
            this.timer = setTimeout(() => {
                this.showMenu();
            }, this.duration);
        },
        onMouseLeave() {
            if (this.disabled) {
                return;
            }
            clearTimeout(this.timer);
            if (this.menu) {
                this.menu.delayHide();
            }
        },
        showMenu() {
            if (this.disabled) {
                return;
            }
            if (this.menu) {
                this.menu.showAt(this.$refs.btnRef, this.menuAlign);
            }
        },
        renderInner() {
            let content = '';
            if (this.isEmpty) {
                content = '';
            } else if (this.text) {
                content = this.text;
            } else if (this.$slots.default) {
                content = this.$slots.default();
            }
            return (
                <>
                    <span class={this.isEmpty ? 'l-btn-text l-btn-empty' : 'l-btn-text'}>
                        {content}
                    </span>
                    <span class={this.btnIconCls}></span>
                    <span class="m-btn-downarrow"></span>
                    <span class="m-btn-line"></span>
                    {this.$slots.default && this.$slots.default()}
                </>
            )
        }

    },
    render() {
        return (
            <a ref="btnRef"
                href={this.href || '#'}
                class={this.innerCls}
                style={Object.assign({}, this.$attrs.style, this.btnStyle)}
                onClick={this.onClick}
                onMouseenter={this.onMouseEnter}
                onMouseleave={this.onMouseLeave}
                onFocus={this.focus}
                onBlur={this.blur}
            >
                <span class={this.btnLeftCls}>
                    {this.renderInner()}
                </span >
                {this.renderOthers()}
            </a >
        )
    }
}