﻿import domHelper from '../base/DomHelper';
import InputBase from '../base/InputBase';

export default {
    name: 'SearchBox',
    extends: InputBase,
    props: {
        menuAlign: {
            type: String,
            default: 'left'
        },
        category: String,
        buttonAlign: {
            type: String,
            default: 'right'
        },
        buttonIconCls: {
            type: String,
            default: 'icon-search'
        }
    },
    data() {
        return {
            hasMenu: true,
            menu: null,
            menuBtnText: null,
            menuBtnIcon: null,
            categoryState: this.category
        }
    },
    watch: {
        category(value) {
            this.categoryState = value;
        }
    },
    computed: {
        text() {
            return this.focused ? this.textState : (this.textFormatter || this.defaultTextFormatter)(this.textState);
        },
        baseClasses() {
            const cls = InputBase.computed.baseClasses();
            return ['searchbox'].concat(cls);
        },
        mbClasses() {
            return ['f-noshrink textbox-button textbox-button-' + this.menuAlign, {
                'f-order0': this.menuAlign == 'left',
                'f-order7': this.menuAlign == 'right'
            }];
        },
        buttonClasses() {
            return ['textbox-addon f-column f-noshrink', {
                'f-order0': this.buttonAlign == 'left',
                'f-order6': this.buttonAlign == 'right'
            }];
        }
    },
    mounted() {
        domHelper.bind(this.$el, 'keydown', this.onKeyDown);
        if (this.$refs.mb) {
            this.menu = this.$refs.mb.menu;
        } else {
            this.hasMenu = false;
        }
        this.initMenu();
    },
    beforeUnmount() {
        domHelper.unbind(this.$el, 'keydown', this.onKeyDown);
    },
    methods: {
        onInput(event) {
            this.textState = event.target.value;
            this.setValue(this.textState);
        },
        onKeyDown(event) {
            if (event.which == 13) {
                event.stopPropagation();
                event.preventDefault();
                this.doSearch();
            }
        },
        setValue(value) {
            this.textState = value;
            InputBase.methods.setValue.call(this, value);
        },
        doSearch() {
            if (this.disabled || this.readonly) {
                return;
            }
            this.$emit('search', {
                value: this.valueState,
                category: this.categoryState
            });
        },
        initMenu() {
            if (this.menu) {
                this.setCategory(this.categoryState);
                this.menu.afterItemClick = (value) => {
                    if (!this.disabled && !this.readonly) {
                        this.setCategory(value);
                    }
                }
            }
        },
        setCategory(value) {
            let item = this.menu.findItem(value);
            if (!item) {
                item = this.menu.subItems[0];
            }
            this.categoryState = item.value || item.text;
            this.menuBtnText = item.text;
            this.menuBtnIcon = item.iconCls;
        },
        renderOthers() {
            let menu = null;
            if (this.$slots.default) {
                this.$slots.default().forEach(m => {
                    if (m.type.name == 'Menu') {
                        menu = m;
                    }
                })
            }
            return (
                <>
                    {
                        menu &&
                        <MenuButton ref="mb"
                            class={this.mbClasses}
                            text={this.menuBtnText}
                            iconCls={this.menuBtnIcon}
                            disabled={this.disabled}
                        >
                            {menu}
                        </MenuButton>
                    }
                    <span class={this.buttonClasses}>
                        <span class={'textbox-icon f-full ' + this.buttonIconCls} onClick={this.doSearch}></span>
                    </span>
                </>
            )
        }
    }
}