﻿import GridHeader from './GridHeader';
import GridBody from './GridBody';
import GridFooter from './GridFooter';

export default {
    name: 'GridView',
    components: {
        GridHeader,
        GridBody,
        GridFooter
    },
    props: {
        columns: {
            type: Array,
            default: () => []
        },
        columnGroup: Object,
        viewIndex: {
            type: Number,
            default: 2
        },
        rows: {
            type: Array,
            default: () => []
        },
        footerRows: {
            type: Array,
            default: () => []
        },
        filterable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            headerPaddingWidth: 0
        }
    },
    computed: {
        viewCls() {
            return 'f-column datagrid-view' + this.viewIndex + (this.viewIndex == 2 ? ' f-full' : ' f-noshrink');
        },
    },
    watch: {
        rows() {
            this.$nextTick(() => {
                this.headerPaddingWidth = this.getHeaderPaddingWidth();
            })
        }
    },
    methods: {
        scrollTop(value) {
            if (value == undefined) {
                return this.$refs.body.scrollTop();
            } else {
                this.$refs.body.scrollTop(value);
            }
        },
        headerHeight(value) {
            if (value == undefined) {
                return this.$refs.header ? this.$refs.header.height() : 0;
            } else {
                if (this.$refs.header) {
                    this.$refs.header.height(value);
                }
            }
        },
        getHeaderPaddingWidth() {
            if (this.viewIndex == 2) {
                let width = this.$refs.body ? this.$refs.body.scrollbarWidth() : 0;
                if (width > 0) {
                    return width;
                }
            }
            return null;
        },
        onBodyScroll(event) {
            // this.headerPaddingWidth = this.getHeaderPaddingWidth();
            if (this.$refs.header) {
                this.$refs.header.scrollLeft(event.left);
            }
            if (this.$refs.footer) {
                this.$refs.footer.scrollLeft(event.left);
            }
            this.$emit('bodyScroll', event);
        },
        onResize() {

        }

    },
    render() {
        return (
            <div class={this.viewCls}>
                <GridHeader ref="header"
                    columnGroup={this.columnGroup}
                    columns={this.columns}
                    paddingWidth={this.headerPaddingWidth}>
                </GridHeader>
                <GridBody ref="body" align="center"
                    columns={this.columns}
                    rows={this.rows}
                    onBodyScroll={this.onBodyScroll}>
                </GridBody>
            </div>
        )
    }
}