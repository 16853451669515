﻿import Dialog from "../dialog/Dialog";
import domHelper from '../base/DomHelper';

export default {
    name: 'Drawer',
    extends: Dialog,
    props: {
        borderType: {
            type: String,
            default: 'none'    // thin,thick,none
        },
        collapsed: {
            type: Boolean,
            default: true
        },
        collapsible: {
            type: Boolean,
            default: false
        },
        closable: {
            type: Boolean,
            default: false
        },
        modal: {
            type: Boolean,
            default: true
        },
        drawerCls: String,
        drawerStyle: Object,
        maskClosable: {
            type: Boolean,
            default: true
        },
        animate: {
            type: Boolean,
            default: true
        },
        region: {
            type: String,
            default: 'west'
        },
        expandIconCls: String,
        collapseIconCls: String
    },
    data() {
        return {
            isExpanding: false,
            isChanging: false,
            collapseToShrinkBody: false
        }
    },
    computed: {
        panelStyles() {
            return [this.panelStyle, this.drawerStyle, {
                left: this.left ? this.left + 'px' : (this.drawerStyle ? this.drawerStyle.left : null),
                top: this.left ? this.top + 'px' : (this.drawerStyle ? this.drawerStyle.top : null),
                width: this.width ? this.width + 'px' : (this.drawerStyle ? this.drawerStyle.width : null),
                height: this.height ? this.height + 'px' : (this.drawerStyle ? this.drawerStyle.height : null)
            }];
        },
        panelClasses() {
            let cls = 'drawer window window-shadow';
            if (this.borderType == 'none') {
                cls += ' window-thinborder window-noborder';
            } else if (this.borderType == 'thin') {
                cls += ' window-thinborder';
            }
            if (this.inline){
                cls += ' inline';
            }
            return ['panel f-column', cls, this.panelCls, this.drawerCls, {
                'layout-collapsed': this.collapsedState,
                'layout-animate': this.animate && !this.isChanging,
                'layout-panel-east': this.region == 'east',
                'layout-panel-west': this.region == 'west',
                'layout-panel-south': this.region == 'south',
                'layout-panel-north': this.region == 'north',
            }];
        },
        collapsibleClasses() {
            let icons = {
                'west': 'left',
                'east': 'right',
                'north': 'up',
                'south': 'down'
            };
            if (this.collapsedState) {
                return this.expandIconCls ? this.expandIconCls : 'layout-button-' + icons[this.region];
            } else {
                return this.collapseIconCls ? this.collapseIconCls : 'layout-button-' + icons[this.region];
            }
        },
    },
    watch: {
        region() {
            this.isChanging = true;
            setTimeout(() => this.isChanging = false)
        },
        collapsed() {
            this.collapsed ? this.collapse() : this.expand()
        }
    },
    mounted() {
        if (this.$refs.panelRef) {
            domHelper.bind(this.$refs.panelRef, 'transitionend', this.onSlideEnd);
        }
    },
    beforeUnmount() {
        if (this.$refs.panelRef) {
            domHelper.unbind(this.$refs.panelRef, 'transitionend');
            // domHelper.unbind(this.$refs.panelRef);
        }
        this.$el.appendChild(this.$refs.panelRef)
        this.close();
    },
    // destroyed() {
    // },
    methods: {
        onSlideEnd() {
            if (this.collapsedState) {
                this.$emit('collapse')
            } else {
                this.$emit('expand')
            }
        },
        initDialog() {
            if (!this.closedState) {
                if (this.$refs.panelRef) {
                    if (!this.inline){
                        document.body.appendChild(this.$refs.panelRef);
                    }
                    this.displaying();
                }
            } else {
                this.closeMask();
            }
        },
        displaying() {
            this.moveToTop();
        },
        clickCollapsibleTool() {
            this.collapse();
        },
        onClickMask() {
            if (this.maskClosable) {
                this.collapse();
            }
        },
        expand() {
            this.collapsedState = false;
            this.isExpanding = true;
            this.openMask();
            this.$nextTick(() => this.isExpanding = false);
            if (!this.animate) {
                this.$emit('expand')
            }
            if (this.maskEl) {
                domHelper.addClass(this.maskEl, 'drawer-mask')
                domHelper.bind(this.maskEl, 'click', this.onClickMask)
            }
        },
        collapse() {
            if (this.maskEl) {
                domHelper.unbind(this.maskEl, 'click');
            }
            this.collapsedState = true;
            this.closeMask();
            if (!this.animate) {
                this.$emit('collapse')
            }
        }
    }

}