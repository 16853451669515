﻿import FieldBase from '../base/FieldBase';

export default {
    name: 'RadioButton',
    extends: FieldBase,
    components: { FieldBase },
    props: {
        value: String,
        name: String,
        inputId: String,
        disabled: {
            type: Boolean,
            default: false
        },
        modelValue: String
    },
    model: {
        prop: 'modelValue',
        event: 'modelChange'
    },
    data() {
        return {
            checked: false
        }
    },
    watch: {
        modelValue() {
            this.initValue();
        }
    },
    computed: {
        radioClasses() {
            return ['f-full', {
                'radiobutton-disabled': this.disabled,
                'radiobutton-checked': this.checked
            }]
        }
    },
    mounted() {
        this.initValue();
    },
    methods: {
        initValue() {
            let checked = (this.value == this.modelValue);
            this.setChecked(checked);
        },
        onClickButton() {
            this.select();
        },
        onChange() {
            this.select();
        },
        isChecked() {
            return this.$refs.inputRef ? this.$refs.inputRef.checked : false;
        },
        setChecked(value) {
            this.$refs.inputRef.checked = value;
            this.checked = value;
        },
        select() {
            if (this.disabled) {
                return;
            }
            this.setChecked(true);
            // this.$emit('modelChange', this.value);
            this.$emit('update:modelValue', this.value);
        }
    },
    render() {
        return (
            <span class={['f-inline-row radiobutton', { 'radiobutton-invalid': this.invalidState }]}>
                <span class={this.radioClasses} onClick={this.onClickButton}>
                    {
                        this.checked &&
                        <span class="radiobutton-inner"></span>
                    }
                </span>
                <div class="radiobutton-value">
                    <input ref="inputRef" id={this.inputId} type="radio" name={this.name} disabled={this.disabled} onChange={this.onChange} />
                </div>
            </span>
        )
    }
}