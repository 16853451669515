﻿import domHelper from '../base/DomHelper';
import GridBody from '../gridbase/GridBody';
import DataGridTable from './DataGridTable';
// import { DomHelper } from '../base/DomHelper';

export default {
    name: 'DataGridBody',
    extends: GridBody,
    components: {
        DataGridTable
    },
    data() {
        return {
            marginTop: 0,
            currRows: []
        }
    },
    computed: {
        view() {
            return this.$parent;
        },
        isVirtualScroll() {
            if (this.view.grid.virtualScroll && this.view.viewIndex == 2) {
                return true;
            } else {
                return false;
            }

        }
    },
    watch: {
        rows() {
            // this.currRows = this.rows;
            // if (this.view.grid.virtualScroll && this.view.viewIndex != 2){
            //     this.currRows = this.rows.slice(0, this.view.grid.pageSize*2);
            // }
            if (this.view.grid.virtualScroll) {
                this.currRows = this.rows.slice(0, this.view.grid.pageSize * 2);
            } else {
                this.currRows = this.rows;
            }
        }
    },
    methods: {
        scrollTop(value) {
            if (value == undefined) {
                if (this.isVirtualScroll) {
                    return this.$refs.vscroll.relativeScrollTop();
                } else {
                    return this.$refs.bodyRef.scrollTop;
                }
            } else {
                if (!this.isVirtualScroll) {
                    this.marginTop = -value;
                }
            }
        },
        scrollbarWidth() {
            if (this.$refs.vscroll) {
                return this.$refs.vscroll.scrollbarWidth();
            } else {
                return domHelper.outerWidth(this.$refs.bodyRef) - domHelper.outerWidth(this.$refs.innerRef);
            }
        },
        onVirtualScroll(event) {
            this.$emit('bodyScroll', event);
        },
        onVirtualPageChange(event) {
            this.view.grid.onVirtualPageChange(event);
        },
        onVirtualPageUpdate(event) {
            this.currRows = event;
            this.view.grid.updateFrozenView(this.$refs.vscroll ? this.$refs.vscroll.scrollTop : 0, this.currRows);
        }
    },
    render() {
        return (
            <div ref="bodyRef" class={'datagrid-body f-full' + (this.isVirtualScroll ? ' datagrid-vbody f-column' : '')} style="margin-top:0" onScroll={this.onScroll}>
                <div ref="innerRef" class={'datagrid-body-inner' + (this.isVirtualScroll ? ' f-column f-full panel-noscroll' : '')} style={{ marginTop: this.marginTop + 'px' }}>
                    {
                        !this.isVirtualScroll &&
                        <DataGridTable columns={this.columns} rows={this.currRows} gridBody={this}></DataGridTable>
                    }
                    {
                        this.isVirtualScroll &&
                        <VirtualScroll ref="vscroll"
                            class="f-full"
                            data={this.rows}
                            total={this.view.grid.total}
                            pageSize={this.view.grid.pageSize}
                            rowHeight={this.view.grid.rowHeight}
                            lazy={this.view.grid.lazy}
                            pageNumber={this.view.grid.pageNumber}
                            onUpdate={this.onVirtualPageUpdate}
                            onBodyScroll={this.onVirtualScroll}
                            onPageChange={this.onVirtualPageChange}
                        >
                            <DataGridTable columns={this.columns} rows={this.currRows} gridBody={this}></DataGridTable>
                        </VirtualScroll>
                    }
                </div>
            </div>
        )
    }
}
