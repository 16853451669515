﻿import { h } from 'vue';
import GridColumn from "./GridColumn";
import GridFilterButton from './GridFilterButton';

export default {
    name: 'GridFilterCell',
    components: {
        GridFilterButton
    },
    props: {
        column: GridColumn,
        grid: Object
    },
    computed: {
        filterValue() {
            return this.column.filterValue;
        }
    },
    watch: {
        'column.filterValue'(value) {
            if (this.$refs.input) {

                this.$refs.input.value = value;
            }
        }
    },
    methods: {
        isOnLeft() {
            if (this.column.filterOperators && this.column.filterOperators.length) {
                if (this.grid.filterBtnPosition == 'left') {
                    return true;
                }
            }
            return false;
        },
        isOnRight() {
            if (this.column.filterOperators && this.column.filterOperators.length) {
                if (this.grid.filterBtnPosition == 'right') {
                    return true;
                }
            }
            return false;
        }

    },
    render() {
        let leftButton = '';
        if (this.isOnLeft()) {
            rightButton = (
                <GridFilterButton class="datagrid-filter-btn datagrid-filter-btn-left f-noshrink"
                    column={this.column}
                    value={this.column.filterOperator}
                />
            )
        }
        let rightButton = '';
        if (this.isOnRight()) {
            rightButton = (
                <GridFilterButton class="datagrid-filter-btn datagrid-filter-btn-right f-noshrink"
                    column={this.column}
                    value={this.column.filterOperator}
                />
            )
        }

        let cell = null;
        if (this.column.$slots['filter']) {
            cell = this.column.$slots['filter']({ column: this.column });
            // } else if (this.column.$scopedSlots.filter){
            //     cell = this.column.$scopedSlots.filter({column:this.column});
        } else {
            cell = (
                <input
                    ref="input"
                    class="datagrid-editable-input datagrid-filter f-full"
                    value={this.column.filterValue}
                    onInput={event=>{
                        this.column.filterValue = event.target.value;
                    }}
                />
            )
        }
        return h(
            'div',
            {
                'class': 'datagrid-filter-c f-row'
            },
            [
                leftButton, cell, rightButton
            ]
        )
    }
}