﻿import GridFooterCell from './GridFooterCell';

export default {
    name: 'GridFooter',
    components: {
        GridFooterCell
    },
    props: {
        columns: {
            type: Array,
            default: () => []
        },
        rows: {
            type: Array,
            default: () => []
        },
        paddingWidth: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            scrollLeftState: 0
        }
    },
    methods: {
        scrollLeft(value) {
            if (value == undefined) {
                return this.scrollLeftState;
            } else {
                this.scrollLeftState = value;
                this.$refs.footerRef.scrollLeft = value;
            }
        }
    },
    render() {
        return (
            <div class="datagrid-footer f-row f-noshrink">
                <div ref="footerRef" class="datagrid-footer-inner f-full">
                    <table class="datagrid-ftable" border="0" cellspacing="0" cellpadding="0">
                        <colgroup>
                            {
                                this.columns.map(col => (
                                    <col style={{ width: col.widthState }}></col>
                                ))
                            }
                        </colgroup>
                        <tbody>
                            {
                                this.rows.map((row, rowIndex) => (
                                    <tr class="datagrid-row">
                                        {
                                            this.columns.map(col => (
                                                <td>
                                                    <GridFooterCell row={row} column={col} rowIndex={rowIndex}></GridFooterCell>
                                                </td>

                                            ))
                                        }
                                    </tr>

                                ))
                            }
                        </tbody>
                    </table>
                </div>
                {this.paddingWidth && <div class="datagrid-footer f-noshrink" style={{ width: this.paddingWidth + 'px' }}></div>}
            </div>
        )
    }
}