﻿export default {
    name: 'ProgressBar',
    props: {
        value: {
            type: Number,
            default: 0
        },
        showValue: {
            type: Boolean,
            default: false
        },
        barCls: String,
        barStyle: Object
    },
    computed: {
        barClasses() {
            return ['progressbar-value f-row f-content-center', this.barCls];
        },
        barStyles() {
            return [this.barStyle, {
                width: this.value + '%'
            }];
        }
    },
    render() {
        return (
            <div class="progressbar f-row">
                <div class={this.barClasses} style={this.barStyles}>
                    {this.showValue && <span>{this.value}%</span>}
                    <slot></slot>
                </div>
            </div>
        )
    }
}