﻿import Dialog from '../dialog/Dialog';
import MessagerContent from './MessagerContent';
import domHelper from '../base/DomHelper';

export default {
    name: 'MessagerDialog',
    extends: Dialog,
    props: {
        component: {
            type: [String, Object],
            default: () => { return MessagerContent }
        },
        messagerIdIndex: Number,
        messagerType: String,
        closeMe: Function,
        dialogStyle: {
            type: Object,
            default: () => { return { width: '360px', minHeight: '130px' } }
        },
        modal: {
            type: Boolean,
            default: true
        },
        closed: {
            type: Boolean,
            default: true
        },
        title: String,
        msg: String,
        icon: String,
        value: String,
        buttons: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            resultValue: null
        }
    },
    computed: {
        messagerIcon() {
            return this.icon ? 'messager-' + this.icon : null;
        }
    },
    mounted() {
        window.EventHub.$on('messagerOpen', (props) => {
            if (this.messagerIdIndex == props.messagerIdIndex) {
                this.open();
            }
        })
        window.EventHub.$on('messagerClose', (props) => {
            if (this.messagerIdIndex == props.messagerIdIndex) {
                this.close();
            }
        })
        window.EventHub.$on('messagerPosition', (props) => {
            if (this.messagerIdIndex == props.messagerIdIndex) {
                this.top = props.top;
                props.outerHeight = domHelper.outerHeight(this.$refs.panelRef);
            }
        })

    },
    methods: {
        close() {
            Dialog.methods.close.call(this);
            this.closeMe(this.resultValue)
        },
        panelBody() {
            return (
                <div ref="bodyRef" v-SlideUpDown={{ animate: this.animateState, collapsed: this.collapsedState, disabled: !this.collapseToShrinkBody }} class={['f-column ', this.bodyClasses]} style={this.bodyStyle}>
                    <MessagerContent
                        messagerType={this.messagerType}
                        title={this.title}
                        msg={this.msg}
                        value={this.value}
                        icon={this.icon}
                        buttons={this.buttons}
                    />
                </div>
            )
        }
    }
}