﻿import { defineComponent } from 'vue';
import domHelper from '../base/DomHelper';

export default defineComponent({
    name: 'GridColumnGroup',
    props: {
        frozen: {
            type: Boolean,
            default: false
        },
        align: {
            type: String,
            default: 'left'
        },
        width: [Number, String]
    },
    data() {
        return {
            widthState: 0,
            rows: []
        }
    },
    mounted() {
        this.widthState = domHelper.toStyleValue(this.width);
        this.$parent.addColumnGroup(this);
    },
    beforeUnmount() {
        this.$parent.removeColumnGroup(this);
    },
    methods: {
        addRow(row){
            this.rows.push(row);
        },
        removeRow(row){
            let index = this.rows.indexOf(row);
            if (index >= 0){
                this.rows.splice(index,1);
            }
        }
    },
    render(){
        return (
            <div>{this.$slots.default()}</div>
        )
    }
})