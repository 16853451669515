﻿import DateBox from '../datebox/DateBox';
import Calendar from '../calendar/Calendar';
import TimeSpinner from '../timespinner/TimeSpinner';
import dateHelper from '../base/DateHelper';

export default {
    name: 'DateTimeBox',
    extends: DateBox,
    components: {
        Calendar,
        TimeSpinner
    },
    props:{
        format: {
            type: String,
            default: 'MM/dd/yyyy HH:mm'
        },
        timeFormat:{
            type: String,
            default: 'HH:mm'
        }
    },
    data(){
        return {
            timeValue: dateHelper.formatDate(new Date(), this.timeFormat)
        }
    },
    methods:{
        onSelectionChange(event){
            this.$emit('selectionChange', event);
        },
        doFilter(value) {
            DateBox.methods.doFilter.call(this, value);
            let date = (this.parser||this.defaultParser)(value);
            if (!date){
                date = this.valueState;
            }
            this.timeValue = dateHelper.formatDate(date, this.timeFormat);
        },
        selectOk(){
            let date = this.$refs.calendar.selectionState;
            if (!date){
                date = new Date();
            }
            let time = dateHelper.parseDate(this.timeValue, this.timeFormat);
            date.setHours(time.getHours());
            date.setMinutes(time.getMinutes());
            date.setSeconds(time.getSeconds());
            this.setValue(date);
            this.closePanel();
        }
    }
}