﻿export class EventHub  {
    constructor(){
        this.events = new Map();
    }

    $on(event, handler) {
        const handlers = this.events.get(event);
        const added = handlers && handlers.push(handler);
        if (!added) {
            this.events.set(event, [handler]);
        }
    }
    $off(event, handler) {
        const handlers = this.events.get(event);
        if (handlers) {
            handlers.splice(handlers.indexOf(handler) >>> 0, 1);
        }
    }
    $emit(event, params) {
        (this.events.get(event) || []).slice().map((handler) => { handler(params); });
        (this.events.get('*') || []).slice().map((handler) => { handler(event, params); });
    }
}