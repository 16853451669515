﻿export default {
    name: 'Label',
    props: {
        for: String,
        align: {
            type: String,
            default: 'left'
        }
    },
    computed: {
        labelClasses() {
            // return 'textbox-label textbox-label-'+this.align;
            return ['textbox-label', {
                'textbox-label-top': this.align == 'top'
            }]
        },
        labelStyle() {
            return {
                textAlign: this.align
            }
        }
    },
    mounted() {
        if (this.for) {
            let att = document.createAttribute('for');
            att.value = this.for;
            this.$el.setAttributeNode(att);
        }
    },
    render() {
        return (
            <label class={this.labelClasses} style={this.labelStyle}>
                {this.$slots.default()}
            </label>
        )
    }
}