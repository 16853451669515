﻿import { defineComponent } from 'vue';
import domHelper from '../base/DomHelper';
import ListBase from '../base/ListBase';
import VirtualScroll from '../base/VirtualScroll';
import { Pagination } from '../pagination';

export default defineComponent({
    name: 'DataList',
    extends: ListBase,
    components: { VirtualScroll },
    props: {
        itemStyle: Object,
        itemCls: String,
        hoverCls: {
            type: String,
            default: 'datagrid-row-over'
        },
        selectedCls: {
            type: String,
            default: 'datagrid-row-selected'
        },
        scrollPosition: [Number, Object]
    },
    computed: {
        innerClasses() {
            return ['f-full', {
                'f-column': this.virtualScroll
            }];
        },
        innerStyle() {
            return { overflow: this.virtualScroll ? 'hidden' : 'auto' };
        },
        virtualItemStyle() {
            return Object.assign({}, this.itemStyle, { height: this.rowHeight + 'px' });
        }
    },
    data() {
        return {
            vrows: [],
            scrollPositionState: this.scrollPosition
        }
    },
    mounted() {
        if (this.$refs.innerRef && this.scrollPositionState) {
            this.$nextTick(() => {
                this.scrollTop(this.scrollPositionState);
                this.scrollPositionState = null;
            });
        }
    },
    methods: {
        getItemClass(row) {
            let cc = [];
            if (this.itemCls) {
                cc.push(this.itemCls);
            }
            if (this.hoverCls && this.highlightRow == row) {
                cc.push(this.hoverCls);
            }
            if (this.selectedCls && this.isSelected(row)) {
                cc.push(this.selectedCls);
            }
            return cc.length ? cc.join(' ') : null;
        },
        getRowIndex(index) {
            if (this.$refs.vscrollRef) {
                return index + this.$refs.vscrollRef.startIndex;
            } else if (this.pagination) {
                return index + (this.pageNumberState - 1) * this.pageSizeState;
            } else {
                return index;
            }
        },
        scrollTop(value) {
            if (value != undefined) {
                if (this.$refs.vscrollRef) {
                    // this.$refs.vscrollRef.scrollTop(value);
                    this.$refs.vscrollRef.scrollState(value);
                } else {
                    this.$refs.innerRef.scrollTop = value;
                }
            } else {
                if (this.$refs.vscrollRef) {
                    // return this.$refs.vscrollRef.scrollTop();
                    return this.$refs.vscrollRef.scrollState();
                } else {
                    return this.$refs.innerRef.scrollTop;
                }
            }
        },
        navRow(step) {
            ListBase.methods.navRow.call(this, step);
            let index = this.rows.indexOf(this.highlightRow);
            if (index >= 0) {
                this.$nextTick(() => {
                    let container = this.$refs.vscrollRef ? this.$refs.vscrollRef.$refs.bodyRef : this.$refs.innerRef;
                    let item = container.querySelector('.' + this.hoverCls);
                    if (item) {
                        domHelper.scrollTo(container, item);
                    }
                })
            }
        },
        highlightFirstRow() {
            this.highlightRow = this.rows.length ? this.rows[0] : null;
            this.navRow(0);
        },
        scrollToSelectedRow() {
            let container = this.$refs.vscrollRef ? this.$refs.vscrollRef.$refs.bodyRef : this.$refs.innerRef;
            let item = container.querySelector('.' + this.selectedCls);
            if (item) {
                domHelper.scrollTo(container, item);
            }
        },
        renderPagination(position) {
            if (!this.pagination) {
                return null;
            }
            if (this.pagePosition != 'both' && this.pagePosition != position) {
                return null;
            }
            let ref = 'pageTopRef';
            let cls = 'datagrid-pager f-noshrink';
            if (this.pagePosition == 'top') {
                cls += ' datagrid-pager-top';
            } else {
                ref = 'pageBottomRef';
            }
            return (
                <Pagination ref={ref} class={cls}
                    total={this.totalState}
                    pageSize={this.pageSizeState}
                    pageNumber={this.pageNumberState}
                    loading={this.loading}
                    onPageChange={this.onPageChange}>
                </Pagination>
            )
        },
        renderLoading() {
            if (!this.loading) {
                return null;
            }
            return (
                <div class="datagrid-loading f-row">
                    <div class="datagrid-mask"></div>
                    <div class="datagrid-mask-msg">{this.loadMsg}</div>
                </div>
            )
        },
        renderEmpty() {
            if (this.loading || this.rows.length) {
                return null;
            }
            if (this.$slots['empty']) {
                const content = this.$slots['empty']();
                return (
                    <div class="datagrid-empty">{content}</div>
                )
            } else {
                return null;
            }
        },
        renderList() {
            if (this.virtualScroll) {
                return null;
            }
            return (
                <>
                    {
                        this.rows.map((row, rowIndex) => (
                            <div
                                class={this.getItemClass(row)}
                                style={this.itemStyle}
                                onMouseenter={() => this.highlightRow = row}
                                onMouseleave={() => this.highlightRow = null}
                                onClick={(event) => this.onRowClick(row, event)}
                            >
                                {this.$slots.default({ row: row, rowIndex: this.getRowIndex(rowIndex) })}
                            </div>
                        ))
                    }
                </>
            )
        },
        renderVirtualList() {
            if (!this.virtualScroll) {
                return null;
            }
            return (
                <VirtualScroll ref="vscrollRef" class="f-full"
                    data={this.rows}
                    total={this.total}
                    pageNumber={this.pageNumber}
                    pageSize={this.pageSize}
                    rowHeight={this.rowHeight}
                    lazy={this.lazy}
                    scrollPosition={this.scrollPosition}
                    onUpdate={value => this.vrows = value}
                    onPageChange={this.onVirtualPageChange}>
                    {
                        this.vrows.map((row, rowIndex) => (
                            <div
                                class={this.getItemClass(row)}
                                style={this.virtualItemStyle}
                                onMouseenter={() => this.highlightRow = row}
                                onMouseleave={() => this.highlightRow = null}
                                onClick={event => this.onRowClick(row, event)}
                            >
                                {this.$slots.default({ row: row, rowIndex: this.getRowIndex(rowIndex) })}
                            </div>
                        ))
                    }
                </VirtualScroll>
            )
        }
    },
    render() {
        let bodyCls = 'panel-body panel-body-noheader datagrid f-full f-column';
        if (!this.border) {
            bodyCls += ' panel-body-noborder';
        }
        return (
            <div class="f-column">
                <div class={bodyCls}>
                    {this.renderPagination('top')}
                    <div ref="innerRef" class={this.innerClasses} style={this.innerStyle}>
                        {this.renderList()}
                        {this.renderVirtualList()}
                    </div>
                    {this.renderPagination('bottom')}
                </div>
                {this.renderLoading()}
                {this.renderEmpty()}
            </div>
        )
    }
})