﻿import domHelper from '../base/DomHelper';
import GridBodyCell from '../gridbase/GridBodyCell';
import DataGridRowDetail from './DataGridRowDetail';
import DataGridRowGroup from './DataGridRowGroup';

export default {
    name: 'DataGridTable',
    components: {
        GridBodyCell,
        DataGridRowDetail,
        DataGridRowGroup
    },
    props: {
        columns: {
            type: Array,
            default: () => []
        },
        rows: {
            type: Array,
            default: () => []
        },
        gridBody: Object
    },
    computed: {
        grid() {
            return this.gridBody.view.grid;
        }
    },
    methods: {
        showExpandIcon() {
            if (this.grid.leftColumns) {
                if (this.gridBody.view.viewIndex == 1) {
                    return true;
                }
            } else if (this.gridBody.view.viewIndex == 2) {
                return true;
            }
            return false;
        },
        groupTitleWidth() {
            return domHelper.outerWidth(this.$refs.groupTitleRef);
        },
        titleLeft() {
            if (this.gridBody.view.viewIndex == 2) {
                if (this.grid.leftColumns) {
                    if (this.grid.view1) {
                        let width = domHelper.outerWidth(this.grid.$refs.view1.$refs.body.$refs.bodyRef);
                        return width - this.grid.expanderWidth;
                    }
                }
            }
            return null;
        },
        onRowClick(row) {
            this.grid.onRowClick(row);
        },
        onRowDblClick(row) {
            this.grid.$emit('rowDblClick', row);
        },
        onRowContextMenu(row, event) {
            this.grid.$emit('rowContextMenu', { row: row, originalEvent: event });
        },
        onCellClick(row, col, event) {
            let cellEl = domHelper.closest(event.currentTarget, '.datagrid-td');
            let rowEl = domHelper.closest(cellEl, '.datagrid-row');
            this.grid.onCellClick(row, col, event);
            if (this.grid.clickToEdit || (this.grid.dblclickToEdit && this.grid.editingItem)) {
                this.doEdit(row, col, rowEl, cellEl);
            }
        },
        onCellDblClick(row, col, event) {
            let cellEl = domHelper.closest(event.currentTarget, '.datagrid-td');
            let rowEl = domHelper.closest(cellEl, '.datagrid-row');
            this.grid.$emit('cellDblClick', { row: row, column: col });
            if (this.grid.dblclickToEdit) {
                this.doEdit(row, col, rowEl, cellEl);
            }
        },
        onCellContextMenu(row, col, event) {
            this.grid.$emit('cellContextMenu', { row: row, column: col, originalEvent: event });
        },
        onCellKeyDown() {
            // if (this.grid.editMode == 'cell'){
            //     setTimeout(() => {
            //         if (event.which == 13){
            //             event.stopPropagation();
            //             this.grid.endEdit();
            //         } else if (event.which == 27){
            //             event.stopPropagation();
            //             this.grid.cancelEdit();
            //         }
            //     });
            // }
        },
        doEdit(row, col, rowEl, cellEl) {
            this.grid.beginEdit(row, col, rowEl);
            setTimeout(() => {
                let input = cellEl.querySelector('.textbox-text');
                if (input) {
                    input.focus();
                }
            });
        },
        onGroupExpanderClick(value, event) {
            event.stopPropagation();
            this.grid.toggleGroup(value);
        },
        onDetailExpanderClick(row, event) {
            event.stopPropagation();
            this.grid.toggleRow(row);
        },
        getRowIndex(rowIndex, row) {
            if (this.grid.groupField) {
                rowIndex = row._rowIndex;
            }
            return this.grid.getAbsoluteIndex(rowIndex);
        },
        getCss(css, row, value, type) {
            if (css) {
                let cssValue = typeof css == 'function' ? css(row, value) : css;
                if (type == 'class') {
                    return typeof cssValue == 'string' ? cssValue : null;
                } else {
                    return typeof cssValue == 'object' ? cssValue : null;
                }
            }
            return null;
        },
        getRowClass(row) {
            return this.getCss(this.grid.rowCss, row, null, 'class');
        },
        getRowStyle(row) {
            return this.getCss(this.grid.rowCss, row, null, 'style');
        },
        getCellClass(column, row) {
            return this.getCss(column.cellCss, row, row[column.field], 'class');
        },
        getCellStyle(column, row) {
            return this.getCss(column.cellCss, row, row[column.field], 'style');
        },
        isEditable(row, col) {
            if (this.grid.isEditing(row, col)) {
                if (col.editable) {
                    return true;
                }
            }
            return false;
        },
        getCellSpan(row, column, rowIndex) {
            return this.grid.cellSpan({ row, column, rowIndex });
        },
        hasCellSpan(row, column, rowIndex) {
            const span = this.getCellSpan(row, column, rowIndex);
            return span.rowspan && span.colspan;
        },
        renderGroupRow(row) {
            if (!row || !this.grid.isGroupRow(row)) {
                return null;
            }
            return (
                <tr class="datagrid-row datagrid-group-row">
                    <td class="datagrid-td-group" colspan={this.columns.length}>
                        <div class="datagrid-group f-row">
                            {
                                this.showExpandIcon() &&
                                <span
                                    class="datagrid-group-expander f-row f-content-center f-noshrink"
                                    style={{ width: this.grid.expanderWidth + 'px' }}
                                    onClick={event => this.onGroupExpanderClick(row.value, event)}>
                                    <span class={'datagrid-row-expander' + (row.collapsed ? ' datagrid-row-expand' : ' datagrid-row-collapse')}>
                                    </span>
                                </span>
                            }
                            <DataGridRowGroup grid={this.grid} left={-this.titleLeft()} row={row}></DataGridRowGroup>
                        </div>
                    </td>
                </tr>
            )
        },
        renderDefaultRow(row, rowIndex) {
            if (!row || this.grid.isGroupRow(row)) {
                return null;
            }
            return (
                <tr class={'datagrid-row ' + this.getRowClass(row) + (this.grid.isHighlighted(row) ? ' datagrid-row-over' : '') + (this.grid.isSelected(row) ? ' datagrid-row-selected' : '') + (this.grid.striped && this.getRowIndex(rowIndex) % 2 ? ' datagrid-row-alt' : '')}
                    style={this.getRowStyle(row)}
                    onMouseenter={() => this.grid.highlightRow = row}
                    onMouseleave={() => this.grid.highlightRow = null}
                    onClick={event => this.onRowClick(row, event)}
                    onDblclick={event => this.onRowDblClick(row, event)}
                    onContextmenu={event => this.onRowContextMenu(row, event)}>
                    {
                        this.columns.map(col => (
                            <>
                                {
                                    col.expander &&
                                    <td class="datagrid-td-expander">
                                        <div class="datagrid-cell f-row f-content-center">
                                            <span class={'datagrid-row-expander' + (this.grid.isRowExpanded(row) ? ' datagrid-row-collapse' : ' datagrid-row-expand')}
                                                onClick={event => this.onDetailExpanderClick(row, event)}>
                                            </span>
                                        </div>
                                    </td>
                                }
                                {
                                    !col.expander && this.hasCellSpan(row, col, rowIndex) &&
                                    <td
                                        class={'datagrid-td ' + this.getCellClass(col, row) + (this.grid.isSelected(row, col) ? ' datagrid-row-selected' : '') + (this.grid.isHighlighted(row, col) ? ' datagrid-row-over' : '')}
                                        style={this.getCellStyle(col, row)}
                                        rowspan={this.getCellSpan(row, col, rowIndex).rowspan}
                                        colspan={this.getCellSpan(row, col, rowIndex).colspan}
                                        onMouseenter={() => this.grid.highlightCell = { row: row, column: col }}
                                        onMouseleave={() => this.grid.highlightCell = null}
                                        onClick={event => this.onCellClick(row, col, event)}
                                        onDblclick={event => this.onCellDblClick(row, col, event)}
                                        onContextmenu={event => this.onCellContextMenu(row, col, event)}
                                        onKeydown={event => this.onCellKeyDown(row, col, event)}>
                                        <GridBodyCell row={row} column={col} rowIndex={this.getRowIndex(rowIndex, row)}></GridBodyCell>
                                    </td>
                                }
                            </>

                        ))
                    }
                </tr>
            )
        },
        renderDetailRow(row, rowIndex) {
            if (this.grid.$slots['detail'] && this.grid.isRowExpanded(row) && !this.grid.isGroupRow(row)) {
                return (
                    <tr>
                        <td colspan={this.columns.length}>
                            <DataGridRowDetail gridBody={this.gridBody} row={row} rowIndex={this.getRowIndex(rowIndex, row)}></DataGridRowDetail>
                        </td>
                    </tr>
                )
            } else {
                return null;
            }
        }

    },
    render() {
        return (
            <table class="datagrid-btable" border="0" cellspacing="0" cellpadding="0">
                <colgroup>
                    {
                        this.columns.map(col => (
                            <col style={{ width: col.widthState }}></col>
                        ))
                    }
                </colgroup>
                <tbody>
                    {
                        (this.rows || []).map((row, rowIndex) => (
                            <>
                                {
                                    this.renderGroupRow(row)
                                }
                                {
                                    this.renderDefaultRow(row, rowIndex)
                                }
                                {
                                    this.renderDetailRow(row, rowIndex)
                                }
                            </>
                        ))
                    }
                </tbody>
            </table>
        )
    }
}