﻿import domHelper from '../base/DomHelper';

export default {
    name: 'TooltipContent',
    props: {
        tooltipIdIndex: Number,
        target: HTMLElement,
        content: [String, Object],
        component: [String, Object, Function],
        propsData: Object,
        store: Object,
        tooltipCls: String,
        tooltipStyle: Object,
        zIndex: {
            type: Number,
            default: 11000000
        },
        position: {
            type: String,
            default: 'bottom'
        },
        trackMouse: {
            type: Boolean,
            default: false
        },
        trackMouseX: {
            type: Number,
            default: 0
        },
        trackMouseY: {
            type: Number,
            default: 0
        },
        deltaX: {
            type: Number,
            default: 0
        },
        deltaY: {
            type: Number,
            default: 0
        },
        valign: {
            type: String,
            default: 'middle'
        },
        showDelay: {
            type: Number,
            default: 200
        },
        hideDelay: {
            type: Number,
            default: 200
        },
        closed: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        tooltipShow: Function,
        tooltipHide: Function
    },
    data() {
        return {
            closedState: this.closed,
            contentState: this.content,
            left: 0,
            top: 0,
            targetWidth: 0,
            targetHeight: 0,
            tipWidth: 0,
            tipHeight: 0,
            showTimer: null,
            hideTimer: null
        }
    },
    watch: {
        closed() {
            this.setClosed(this.closed);
        },
        content() {
            this.contentState = this.content;
            if (this.$refs.tooltipRef) {
                this.$nextTick(() => {
                    this.setClosed(this.closedState)
                })
            }
        }
    },
    computed: {
        tooltipClasses() {
            return ['tooltip', 'tooltip-' + this.position, this.tooltipCls];
        },
        tooltipStyles() {
            return [this.tooltipStyle, {
                left: this.left + 'px',
                top: this.top + 'px',
                display: 'block',
                zIndex: this.zIndex
            }];
        }
    },
    mounted() {
        window.EventHub.$on('tooltipUpdate', (props) => {
            if (this.tooltipIdIndex == props.tooltipIdIndex) {
                this.contentState = props.content;
                this.setClosed(this.closedState)
            }
        })
        window.EventHub.$on('tooltipShow', (props) => {
            if (this.tooltipIdIndex == props.tooltipIdIndex) {
                this.show();
            }
        })
        window.EventHub.$on('tooltipHide', (props) => {
            if (this.tooltipIdIndex == props.tooltipIdIndex) {
                this.hide();
            }
        })
    },
    methods: {
        onMouseEnter() {
            this.show();
        },
        onMouseLeave() {
            this.hide();
        },
        getPosition(pos = 'bottom') {
            pos = pos || 'bottom';
            let left = 0;
            let top = 0;
            let offset = domHelper.offset(this.target);
            let targetWidth = this.targetWidth;
            let targetHeight = this.targetHeight;
            let tipWidth = this.tipWidth;
            let tipHeight = this.tipHeight;
            if (this.trackMouse) {
                left = this.trackMouseX + this.deltaX;
                top = this.trackMouseY + this.deltaY;
                targetWidth = targetHeight = 0;
            } else {
                left = offset.left + this.deltaX;
                top = offset.top + this.deltaY;
            }
            switch (pos) {
                case 'right':
                    left += targetWidth + 12 + (this.trackMouse ? 12 : 0);
                    if (this.valign == 'middle') {
                        top -= (tipHeight - targetHeight) / 2;
                    }
                    break;
                case 'left':
                    left -= tipWidth + 12 + (this.trackMouse ? 12 : 0);
                    if (this.valign == 'middle') {
                        top -= (tipHeight - targetHeight) / 2;
                    }
                    break;
                case 'top':
                    left -= (tipWidth - targetWidth) / 2;
                    top -= tipHeight + 12 + (this.trackMouse ? 12 : 0);
                    break;
                case 'bottom':
                    left -= (tipWidth - targetWidth) / 2;
                    top += targetHeight + 12 + (this.trackMouse ? 12 : 0);
                    break;
            }
            return {
                left: left,
                top: top
            };
        },
        reposition() {
            if (!this.$refs.tooltipRef) {
                return;
            }
            let view = domHelper.getViewport();
            let pos = this.getPosition(this.position);
            if (this.position == 'top' && pos.top < domHelper.getScrollTop()) {
                pos = this.getPosition('bottom');
            } else if (this.position == 'bottom') {
                if (pos.top + this.tipHeight > view.height + domHelper.getScrollTop()) {
                    pos = this.getPosition('top');
                }
            }
            if (pos.left < domHelper.getScrollLeft()) {
                if (this.position == 'left') {
                    pos = this.getPosition('right');
                } else {
                    let arrowLeft = this.tipWidth / 2 + pos.left - domHelper.getScrollLeft();
                    this.$refs.arrowOuterRef.style.left = arrowLeft + 'px';
                    this.$refs.arrowInnerRef.style.left = arrowLeft + 'px';
                    pos.left = domHelper.getScrollLeft();
                }
            } else if (pos.left + this.tipWidth > view.width + domHelper.getScrollLeft()) {
                if (this.position == 'right') {
                    pos = this.getPosition('left');
                } else {
                    let arrowLeft = pos.left;
                    pos.left = view.width + domHelper.getScrollLeft() - this.tipWidth;
                    arrowLeft = this.tipWidth / 2 - (pos.left - arrowLeft);
                    this.$refs.arrowOuterRef.style.left = arrowLeft + 'px';
                    this.$refs.arrowInnerRef.style.left = arrowLeft + 'px';
                }
            }
            this.left = pos.left;
            this.top = pos.top;
            let bc = 'border-' + this.position + '-color';
            let borderColor = this.$refs.tooltipRef.style.borderColor;
            let backColor = this.$refs.tooltipRef.style.backgroundColor;
            this.$refs.arrowOuterRef.style[bc] = borderColor;
            this.$refs.arrowInnerRef.style[bc] = backColor;
        },
        setClosed(closed) {
            this.closedState = closed;
            if (!this.closedState) {
                this.$nextTick(() => {
                    // this.$refs.tooltipRef.style.display = 'block';
                    this.targetWidth = domHelper.outerWidth(this.target);
                    this.targetHeight = domHelper.outerHeight(this.target);
                    this.tipWidth = domHelper.outerWidth(this.$refs.tooltipRef);
                    this.tipHeight = domHelper.outerHeight(this.$refs.tooltipRef);
                    this.reposition();
                    if (this.tooltipShow) {
                        this.tooltipShow();
                    }
                });
            } else {
                if (this.tooltipHide) {
                    this.tooltipHide();
                }
            }
        },
        show() {
            if (this.disabled) {
                return;
            }
            if (!this.contentState && !this.component) {
                return;
            }
            this.clearTimeouts();
            this.showTimer = setTimeout(() => {
                this.setClosed(false);
            }, this.showDelay);
        },
        hide() {
            this.clearTimeouts();
            this.hideTimer = setTimeout(() => {
                this.setClosed(true);
            }, this.hideDelay);
        },
        clearTimeouts() {
            clearTimeout(this.showTimer);
            clearTimeout(this.hideTimer);
        }

    },
    render() {
        if (this.closedState) {
            return null;
        }
        if (!this.contentState) {
            return null;
        }
        return (
            <div ref="tooltipRef" tabindex="-1"
                class={this.tooltipClasses}
                style={this.tooltipStyles}
                onMouseenter={this.onMouseEnter}
                onMouseleave={this.onMouseLeave}>
                <div class="tooltip-content">
                    {this.contentState}
                </div>
                <div ref="arrowOuterRef" class="tooltip-arrow-outer"></div>
                <div ref="arrowInnerRef" class="tooltip-arrow"></div>
            </div>
        )
    }
}
