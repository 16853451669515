﻿import SlideUpDown from '../base/SlideUpDown';
import TreeGridRow from './TreeGridRow';

export default {
    name: 'TreeGridChildren',
    components: {
        TreeGridRow
    },
    directives: { SlideUpDown },
    props: {
        gridBody: Object,
        rows: {
            type: Array,
            default: () => []
        },
        prow: Object,
        columns: Array,
        depth: {
            type: Number,
            default: 0
        }
    },
    computed: {
        grid() {
            return this.gridBody.view.grid;
        }
    },
    render() {
        return (
            <table class="datagrid-btable" border="0" cellspacing="0" cellpadding="0">
                <colgroup>
                    {
                        this.columns.map(col => (
                            <col style={{ width: col.widthState }}></col>
                        ))
                    }
                </colgroup>
                <tbody>
                    {
                        (this.rows||[]).map((row) => (
                            <>
                                <TreeGridRow gridBody={this.gridBody} row={row} prow={this.prow} columns={this.columns} depth={this.depth}></TreeGridRow>
                                {
                                    row.children && row.children.length > 0 &&
                                    <tr class="treegrid-tr-tree">
                                        <td colspan={this.columns.length} style="border:0">
                                            <div v-SlideUpDown={{animate:this.grid.animate,collapsed:row.state=='closed',disabled:false}}>
                                                <TreeGridChildren gridBody={this.gridBody} rows={row.children} prow={row} columns={this.columns} depth={this.depth + 1}></TreeGridChildren>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </>
                        ))
                    }
                </tbody>
            </table>
        )
    }
}