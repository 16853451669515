﻿import domHelper from '../base/DomHelper';
import dateHelper from '../base/DateHelper';
import ComboBase from '../base/ComboBase';
import Calendar from '../calendar/Calendar';
import { FieldBase } from '../base';

export default {
    name: 'DateBox',
    extends: ComboBase,
    components: {
        Calendar
    },
    props: {
        value: Date,
        modelValue: Date,
        format: {
            type: String,
            default: 'MM/dd/yyyy'
        },
        currentText: {
            type: String,
            default: () => window.Locale.t('DateBox.currentText', 'Today')
        },
        closeText: {
            type: String,
            default: () => window.Locale.t('DateBox.closeText', 'Close')
        },
        okText: {
            type: String,
            default: () => window.Locale.t('DateBox.okText', 'Ok')
        },
        showWeek: {
            type: Boolean,
            default: false
        },
        weekNumberHeader: {
            type: String,
            default: ''
        },
        firstDay: {
            type: Number,
            default: 0
        },
        formatter: Function,
        parser: Function,
        validator: {
            type: Function,
            default: () => { return true }
        },
        reversed: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            timer: null
        }
    },
    computed: {
        text() {
            if (!this.focused && this.panelClosed) {
                this.textState = (this.formatter || this.defaultFormatter)(this.valueState);
            }
            return this.textState;
        }
    },
    mounted() {
        // this.$on('blur', () => {
        //     if (this.panelClosed) {
        //         return;
        //     }
        //     if (!this.textState.trim()) {
        //         this.setValue(null);
        //     }
        // });
        domHelper.bind(this.$refs.inputRef, 'keydown', this.onKeyDown);
    },
    beforeUnmount() {
        domHelper.unbind(this.$refs.inputRef, 'keydown', this.onKeyDown);
    },
    methods: {
        afterBlur() {
            FieldBase.methods.afterBlur.call(this);
            if (this.panelClosed) {
                return;
            }
            if (!this.textState.trim()) {
                this.setValue(null);
            } else if (!this.reversed) {
                const val = (this.parser || this.defaultParser)(this.textState.trim());
                const y = val.getFullYear();
                if (!y) {
                    val.setFullYear(1900);
                }
                this.setValue(val);
            }
        },
        onInput(event) {
            this.textState = event.target.value;
            if (this.focused) {
                if (this.panelClosed) {
                    this.openPanel();
                }
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.doFilter(this.textState);
                }, this.delay);
            }
        },
        onSelectionChange(event) {
            this.setValue(event);
            this.closePanel();
            this.$emit('selectionChange', event);
        },
        onKeyDown(event) {
            if (this.panelClosed && event.which == 40) {
                this.openPanel();
                event.preventDefault();
                return;
            }
            if (this.panelClosed) {
                return;
            }
            switch (event.which) {
                case 40:	// down
                    this.$refs.calendar.navDate(7);
                    event.preventDefault();
                    break;
                case 38:	// up
                    this.$refs.calendar.navDate(-7);
                    event.preventDefault();
                    break;
                case 37:	// left
                    this.$refs.calendar.navDate(-1);
                    event.preventDefault();
                    break;
                case 39:	// right
                    this.$refs.calendar.navDate(1);
                    event.preventDefault();
                    break;
                case 13:	// enter
                    this.$refs.calendar.selectDate();
                    this.closePanel();
                    this.textState = (this.formatter || this.defaultFormatter)(this.valueState);
                    event.preventDefault();
                    break;
            }
        },
        defaultFormatter(date) {
            return dateHelper.formatDate(date, this.format);
        },
        defaultParser(value) {
            return dateHelper.parseDate(value, this.format);
        },
        doFilter(value) {
            let date = (this.parser || this.defaultParser)(value);
            if (!date) {
                date = this.valueState;
            }
            this.$refs.calendar.moveTo(date);
            this.$refs.calendar.highlightDate(date);
        },
        selectToday() {
            this.setValue(new Date());
            this.closePanel();
        },
        renderPanel() {
            if (this.panelClosed) {
                return null;
            }
            const style = Object.assign({}, this.panelStyle, {
                left: this.panelLeft + 'px',
                top: this.panelTop + 'px'
            })
            return (
                <div ref="panelRef"
                    class="panel-body panel-body-noheader combo-panel combo-p f-column"
                    style={style}>
                    {this.renderContent()}
                </div>
            )
        },
        renderContent() {
            return (
                <>
                    <Calendar ref="calendar" class="f-full"
                        border={false}
                        showWeek={this.showWeek}
                        weekNumberHeader={this.weekNumberHeader}
                        firstDay={this.firstDay}
                        validator={this.validator}
                        selection={this.valueState}
                        onSelectionChange={this.onSelectionChange}>
                    </Calendar>
                    <div class="datebox-button f-row">
                        <a href="javascript:;" class="datebox-button-a f-full" onClick={this.selectToday}>{this.currentText}</a>
                        <a href="javascript:;" class="datebox-button-a f-full" onClick={this.closePanel}>{this.closeText}</a>
                    </div>
                </>
            )
        }
    }
}