﻿import { TextBox } from "../textbox";
export default {
    name: 'GridBodyCell',
    props: {
        row: Object,
        column: Object,
        rowIndex: Number
    },
    data() {
        return {
            error: null
        }
    },
    computed: {
        grid() {
            let t = this;
            while (t.$options.name !== 'DataGrid' && t.$options.name !== 'TreeGrid') {
                t = t.$parent;
            }
            return t;
        }
    },
    methods: {
        onKeyDown(event) {
            if (this.grid.editMode == 'cell') {
                setTimeout(() => {
                    if (event.which == 13) {
                        event.stopPropagation();
                        this.grid.endEdit();
                    } else if (event.which == 27) {
                        event.stopPropagation();
                        this.grid.cancelEdit();
                    }
                });
            }
        },
        onValidate(errors) {
            if (!this.grid.editingItem) {
                return;
            }
            const field = this.column.field;
            let error = errors[field];
            error = error ? error[0] : null;
            this.error = error;
            let editingItem = this.grid.editingItem;
            editingItem.errors = editingItem.errors || {};
            Object.assign(editingItem.errors, { [field]: errors[field] });
            let errCount = 0;
            for (let field in editingItem.errors) {
                errCount += editingItem.errors[field].length;
            }
            editingItem.invalid = errCount > 0;
            this.grid.$emit('editValidate', editingItem);
        }
    },
    render() {
        let isEditable = this.$parent.isEditable(this.row, this.column);
        let cell = null;
        if (isEditable) {
            if (this.column.$slots['edit']) {
                cell = this.column.$slots['edit']({
                    row: this.row,
                    column: this.column,
                    rowIndex: this.rowIndex,
                    error: this.error
                });
                cell.forEach(c => {
                    c.props.name = this.column.field;
                });
            } else {
                cell = (
                    <TextBox
                        class="f-full"
                        name={this.column.field}
                        value={this.row[this.column.field]}
                        onValueChange={e => {
                            this.row[this.column.field] = e.currentValue;
                            this.$refs.form.validate();
                        }}
                    />
                )
            }
            cell = (
                <Form
                    ref="form"
                    class="f-column"
                    model={this.row}
                    rules={{ [this.column.field]: this.column.editRules }}
                    messages={{ [this.column.field]: this.column.editMessages }}
                    onValidate={errors => {
                        this.onValidate(errors);
                    }}
                    onSubmit={event => event.preventDefault()}
                    onKeydown={this.onKeyDown}
                    v-Tooltip={Object.assign({
                        closed: !this.error,
                        content: this.error
                    }, this.grid.tipOptions)}
                >
                    {cell}
                </Form>
            )
        } else {
            if (this.column.$slots['body']) {
                cell = this.column.$slots['body']({
                    row: this.row,
                    column: this.column,
                    rowIndex: this.rowIndex
                });
            } else if (this.column.$slots['cell']) {
                cell = this.column.$slots['cell']({
                    row: this.row,
                    column: this.column,
                    rowIndex: this.rowIndex
                });
            } else {
                cell = this.row[this.column.field];
                // cell = String(this.row[this.column.field]);
            }
        }
        return (
            <div
                class={['datagrid-cell', { 'datagrid-editable': isEditable }]}
                style={{ textAlign: this.column.align || null }}
            >
                {cell}
            </div>
        )
    }
}