﻿export default {
    name: 'TreeGridTitle',
    props: {
        row: Object,
        column: Object,
        rowIndex: Number
    },
    render() {
        let cell = '';
        if (this.column.$slots['body']) {
            cell = this.column.$slots['body']({
                row: this.row,
                column: this.column,
                rowIndex: this.rowIndex
            });
        } else if (this.column.$slots['cell']) {
            cell = this.column.$slots['cell']({
                row: this.row,
                column: this.column,
                rowIndex: this.rowIndex
            });
        } else {
            cell = this.row[this.column.field];
        }
        return (
            <span class="tree-title">{cell}</span>
        )
    }
}