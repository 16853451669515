﻿import { defineComponent } from 'vue';

export default defineComponent({
    name: 'GridHeaderRow',
    data() {
        return {
            columns: [],
            timer: null
        }
    },
    watch: {
        columns() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.$parent.$parent.initColumns()
                this.$parent.$parent.initHeaderHeight()
                this.timer = null;
            })
        }
    },
    mounted() {
        this.$parent.addRow(this);
    },
    beforeUnmount() {
        this.$parent.removeRow(this);
    },
    methods: {
        addColumn(column) {
            const cc = this.columns.slice();
            cc.push(column);
            this.columns = cc;
        },
        removeColumn(column) {
            const cc = this.columns.slice();
            let index = cc.indexOf(column);
            if (index >= 0) {
                cc.splice(index, 1);
                this.columns = cc;
            }
        },

    },
    render(){
        return (
            <div>{this.$slots.default()}</div>
        )
    }
})