﻿export default {
    name: 'MenuItem',
    props: {
        value: [Number, String],
        text: String,
        iconCls: String,
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isActived: false,
            subMenu: null
        }
    },
    computed: {
        menu() {
            if (this.$parent.$options.name == 'Menu') {
                return this.$parent;
            } else {
                return this.$parent.menu;
            }
        },
        itemClasses() {
            return ['menu-item', {
                'menu-active': this.isActived,
                'menu-item-disabled': this.disabled,
                'menu-active-disabled': this.disabled && this.isActived
            }]
        }
    },
    mounted() {
        this.$parent.addItem(this);
    },
    beforeUnmount() {
        this.$parent.removeItem(this);
    },
    methods: {
        highlight() {
            this.$parent.subItems.forEach((item) => {
                item.unhighlight();
            });
            this.isActived = true;
            if (this.subMenu) {
                this.$nextTick(() => this.subMenu.alignMenu());
            }
        },
        unhighlight() {
            if (this.subMenu) {
                this.subMenu.unhighlight();
            }
            this.isActived = false;
        },
        onClickItem(event) {
            event.stopPropagation();
            if (this.disabled) {
                return;
            }
            this.menu.$emit('itemClick', this.value || this.text);
            this.menu.afterItemClick(this.value || this.text);
            if (!this.subMenu) {
                this.menu.unhighlight();
                this.menu.hide();
            }
        }

    },
    render() {
        return (
            <div class={this.itemClasses}
                onMouseenter={this.highlight}
                onMouseleave={this.unhighlight}
                onClick={this.onClickItem}
            >
                {!this.$slots['item'] && <div class="menu-text">{this.text}</div>}
                {this.$slots['item'] && this.$slots['item']()}
                {this.iconCls && <div class={['menu-icon', this.iconCls]}></div>}
                {this.subMenu && <div class="menu-rightarrow"></div>}
                {this.$slots.default && this.$slots.default()}
            </div>
        )
    }
}