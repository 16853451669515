﻿export default {
    name: 'PaginationList',
    computed: {
        pageList() {
            return this.$parent.pageList;
        },
        pageSize() {
            return this.$parent.pageSizeState;
        }
    },
    methods: {
        onChange(event) {
            this.$parent.pageSizeState = parseInt(event.target.value);
        }
    },
    render() {
        return (
            <select class="pagination-page-list" onChange={this.onChange}>
                {
                    this.pageList.map(page => (
                        <option selected={page == this.pageSize}>{page}</option>
                    ))
                }
            </select>
        )
    }
}