﻿export default {
    name: 'DataGridRowDetail',
    props: {
        gridBody: Object,
        row: Object,
        rowIndex: Number
    },
    computed: {
        grid() {
            return this.gridBody.view.grid;
        }
    },
    render() {
        if (this.gridBody.view.viewIndex == 2) {
            return (
                <div class="datagrid-row-detail">
                    {
                        this.grid.$slots['detail']({
                            row: this.row,
                            rowIndex: this.rowIndex
                        })
                    }
                </div>
            )
        } else {
            return '';
        }
    }
}