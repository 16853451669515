﻿export default {
    name: 'PaginationManual',
    props: {
        pageNumber: Number
    },
    data() {
        return {
            value: this.pageNumber
        }
    },
    watch: {
        pageNumber() {
            this.value = this.pageNumber;
        }
    },
    methods: {
        onBlur() {
            const page = parseInt(this.value, 10) || 1;
            this.value = page;
            this.$emit('pageInput', page);
        },
        onKeyDown(event) {
            if (event.keyCode === 13) {
                this.onBlur()
            }
        }
    },
    render() {
        return (
            <span style="margin:0 6px">
                <span>{this.$parent.beforePageText}</span>
                <input
                    class="pagination-num"
                    type="text"
                    v-model={this.value}
                    size="2"
                    onBlur={this.onBlur}
                    onKeydown={this.onKeyDown}
                />
                <span>{this.$parent.afterPageInfo}</span>
            </span>
        )
    }
}