﻿import { defineComponent } from "vue";

export default defineComponent({
    name: 'FieldBase',
    template: '',
    props: {
        name: String,
        invalid: {
            type: Boolean,
            default: false
        },
        validateOnCreate: {
            type: Boolean,
            default: true
        },
        validateOnBlur: {
            type: Boolean,
            default: false
        },
        validateOnChange: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            invalidState: this.invalid
        }
    },
    watch: {
        invalid(value) {
            this.invalidState = value;
        }
    },
    computed: {
        form() {
            let parent = this.$parent;
            while (parent && parent.$options.name != 'Form') {
                parent = parent.$parent;
            }
            return parent;
        },
        field() {
            let parent = this.$parent;
            while (parent && parent.$options.name != 'FormField') {
                parent = parent.$parent;
            }
            return parent;
        },
        fieldName() {
            if (this.name) {
                return this.name;
            }
            return this.field ? this.field.name : null;
        }
    },
    mounted() {
        if (this.form && this.form.fieldAdd) {
            this.form.fieldAdd(this);
        }
    },
    beforeUnmount() {
        if (this.form && this.form.fieldRemove) {
            this.form.fieldRemove(this);
        }
    },
    methods: {
        renderField() {
            return null;
        },
        afterFocus() { },
        afterBlur() {
            if (this.form && this.form.fieldBlur) {
                this.form.fieldBlur(this)
            }
        },
        afterValueChange() {
            if (this.form && this.form.fieldChange) {
                this.form.fieldChange(this)
            }
        },
        setValid(valid) {
            this.invalidState = !valid;
        }
    },
    render() {
        return this.renderField()
    }
})