﻿import SlideUpDown from '../base/SlideUpDown';
import TreeNodeTitle from './TreeNodeTitle';
import TreeNodeEditor from './TreeNodeEditor';
import domHelper from '../base/DomHelper';
import Draggable from '../draggable/Draggable';
import Droppable from '../droppable/Droppable';
import treeHelper from '../base/TreeHelper';
import TreeNodeIcon from './TreeNodeIcon';

export default {
    name: 'TreeNode',
    components: { TreeNodeTitle, TreeNodeEditor, TreeNodeIcon },
    directives: {
        SlideUpDown,
        Draggable,
        Droppable
    },
    props: {
        tree: Object,
        node: Object,
        pnode: Object,
        depth: {
            type: Number,
            default: 0
        },
        nodeCls: String
    },
    data() {
        return {
            loading: false,
            innerNode: this.node,
            dndCls: null
        }
    },
    created() {
        this.node.parent = this.pnode;
    },
    computed: {
        indentWidth() {
            if (this.isLeaf) {
                return (this.depth + 1) * 16;
            } else {
                return this.depth * 16;
            }
        },
        nodeClasses() {
            return ['tree-node f-row f-vcenter', this.dndCls, this.node.nodeCls, {
                'tree-node-hover': this.node == this.tree.highlightNode,
                'tree-node-selected': this.isSelected,
                'tree-node-disabled': this.node.disabled
            }];
        },
        hitClasses() {
            return ['tree-hit', {
                'tree-expanded': this.isExpanded,
                'tree-collapsed': this.isCollapsed
            }];
        },
        iconClasses() {
            return ['tree-icon tree-folder', this.node.iconCls, {
                'tree-folder-open': this.isExpanded,
                'tree-file': this.isLeaf,
                'tree-loading': this.loading
            }];
        },
        checkboxClasses() {
            let cc = ['unchecked', 'checked', 'indeterminate'];
            let index = cc.indexOf(this.node.checkState);
            if (index == -1) {
                index = 0;
            }
            return 'tree-checkbox tree-checkbox' + index;
        },
        isExpanded() {
            if (!this.node.state || this.node.state == 'open') {
                return true;
            } else {
                return false;
            }
        },
        isCollapsed() {
            if (this.node.state && this.node.state == 'closed') {
                return true;
            } else {
                return false;
            }
        },
        isSelected() {
            return this.node == this.tree.selectionState;
        },
        isLeaf() {
            if (this.node.state == 'closed') {
                return false;
            } else {
                if (this.node.children && this.node.children.length) {
                    this.loading = false;
                    return false;
                } else {
                    if (this.loading) {
                        return false;
                    }
                    return true;
                }
            }
        }
    },
    methods: {
        getDraggableOpts() {
            return {
                disabled: this.tree.dnd ? this.node.disabled : true,
                revert: true,
                deltaX: 0,
                deltaY: 0,
                edge: 5,
                scope: this.tree.dragScope,
                proxy: this.tree.$refs.proxy,
                dragStart: (event) => { this.onDragStart(event) },
                dragEnd: (event) => { this.onDragEnd(event) }
            }
        },
        getDroppableOpts() {
            return {
                disabled: this.tree.dnd ? (this.node.disabled || this.node.dropDisabled) : true,
                node: this.node,
                dragOver: (scope) => { this.onDragOver(scope) },
                dragLeave: (scope) => { this.onDragLeave(scope) },
                drop: (scope) => { this.onDrop(scope) }
            }
        },
        onDragStart(event) {
            Object.assign(this.tree.dragScope, {
                node: this.node,
                event: event,
                obj: this
            })
            this.tree.dragCls = 'tree-dnd-no';
            treeHelper.forNodes([this.node], (n) => {
                n.dropDisabled = true;
            })
        },
        onDragEnd() {
            treeHelper.forNodes(this.tree.innerData, (node) => {
                node.dropDisabled = false;
            })
        },
        onDragOver(scope) {
            if (this.node.dropDisabled) {
                this.tree.dragCls = 'tree-dnd-no';
                return;
            }
            this.tree.dragCls = 'tree-dnd-yes';
            const event = scope.event;
            const el = event.target.currDroppable.$el;
            const top = domHelper.offset(el).top;
            const bottom = top + domHelper.outerHeight(el);
            const pageY = event.pageY;
            if (pageY > top + (bottom - top) / 2) {
                if (bottom - pageY < 5) {
                    scope.point = 'bottom';
                    this.dndCls = 'tree-node-bottom';
                    // this.$set(this, 'dndCls', 'tree-node-bottom')
                } else {
                    scope.point = 'append';
                    this.dndCls = 'tree-node-append';
                    // this.$set(this, 'dndCls', 'tree-node-append')
                }
            } else {
                if (pageY - top < 5) {
                    scope.point = 'top';
                    this.dndCls = 'tree-node-top';
                    // this.$set(this, 'dndCls', 'tree-node-top')
                } else {
                    scope.point = 'append';
                    this.dndCls = 'tree-node-append';
                    // this.$set(this, 'dndCls', 'tree-node-append')
                }
            }

        },
        onDragLeave() {
            this.dndCls = null;
            // this.$set(this, 'dndCls', null)
            this.tree.dragCls = 'tree-dnd-no';
        },
        onDrop(scope) {
            this.dndCls = null;
            // this.$set(this, 'dndCls', null)
            this.tree.dragCls = null;
            if (scope.point) {
                this.tree.$emit('nodeDrop', { from: scope.node, to: this.node, point: scope.point })
                this.tree.moveNode(scope.node, this.node, scope.point)
                this.$nextTick(() => {
                    const event = scope.event;
                    const el = event.target.$el;
                    el.style.left = null;
                    el.style.top = null;
                })
            }
        },
        toggle(event) {
            event.stopPropagation();
            if (this.isExpanded) {
                this.node.state = 'closed';
                // this.$set(this.node, 'state', 'closed');
                this.tree.$emit('nodeCollapse', this.node);
            } else {
                this.loading = true;
                this.node.state = 'open';
                // this.$set(this.node, 'state', 'open');
                this.tree.$emit('nodeExpand', this.node);
            }
        },
        onClickNode(event) {
            const { clickToEdit, dblclickToEdit, editingItem } = this.tree;
            event.stopPropagation();
            this.tree.$emit('nodeClick', this.node);
            this.tree.selectNode(this.node);
            if (clickToEdit || (dblclickToEdit && editingItem)) {
                this.tree.beginEdit(this.node, domHelper.closest(event.target, '.tree-node'));
            }
        },
        onDblClickNode(event) {
            event.stopPropagation();
            this.tree.$emit('nodeDblClick', this.node);
            if (this.tree.dblclickToEdit) {
                this.tree.beginEdit(this.node, domHelper.closest(event.target, '.tree-node'));
            }
        },
        onCheckNode(event) {
            event.stopPropagation();
            if (this.node.checkState == 'checked') {
                this.tree.uncheckNode(this.node);
            } else {
                this.tree.checkNode(this.node);
            }
        },
        onNodeContextMenu(event) {
            this.tree.$emit('nodeContextMenu', { node: this.node, originalEvent: event });
        }

    },
    render() {
        return (
            <li>
                <div class={this.nodeClasses}
                    onMouseenter={() => this.tree.highlightNode = this.node}
                    onMouseleave={() => this.tree.highlightNode = null}
                    onContextmenu={this.onNodeContextMenu}
                    onClick={this.onClickNode}
                    onDblclick={this.onDblClickNode}
                    v-Draggable={this.getDraggableOpts()}
                    v-Droppable={this.getDroppableOpts()}
                >
                    <span class="tree-indent" style={{ width: this.indentWidth + 'px' }}></span>
                    {!this.isLeaf && <span class={this.hitClasses} onClick={this.toggle}></span>}
                    {!this.tree.$slots['icon'] && <span class={this.iconClasses}></span>}
                    {this.tree.$slots['icon'] && <TreeNodeIcon tree={this.tree} node={this.node}></TreeNodeIcon>}
                    {this.tree.checkboxState && <span class={this.checkboxClasses} onClick={this.onCheckNode}></span>}
                    {!this.tree.isEditing(this.node) && <TreeNodeTitle tree={this.tree} node={this.node}></TreeNodeTitle>}
                    {this.tree.isEditing(this.node) && <TreeNodeEditor tree={this.tree} node={this.node}></TreeNodeEditor>}
                </div>
                {
                    this.node.children && this.node.children.length > 0 &&
                    <ul class="f-block" v-SlideUpDown={{ animate: this.tree.animate, collapsed: this.node.state == 'closed', disabled: false }}>
                        {
                            this.node.children.map(cnode => (
                                <>
                                    {
                                        !cnode.hidden &&
                                        <TreeNode node={cnode} pnode={this.node} depth={this.depth + 1} tree={this.tree}></TreeNode>
                                    }
                                </>
                            ))
                        }
                    </ul>
                }
            </li>
        )
    }
}