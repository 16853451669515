﻿import ComboBase from '../base/ComboBase';
import TimePanel from './TimePanel';
import InputBase from '../base/InputBase';

export default {
    name: 'TimePicker',
    extends: ComboBase,
    components: {
        TimePanel
    },
    props: {
        value: String,
        modelValue: String,
        ampm: {
            type: Array,
            default: () => ['am', 'pm']
        },
        hour24: {
            type: Boolean,
            default: () => false
        },
        hourDistance: {
            type: Array,
            default: () => [20, 50]
        },
        closeText: {
            type: String,
            default: () => window.Locale.t('DateBox.closeText', 'Close')
        },
        okText: {
            type: String,
            default: () => window.Locale.t('DateBox.okText', 'Ok')
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectingValue: null
        }
    },
    mounted() {
        const v = this.value !== undefined ? this.value:this.modelValue;
        this.setValue(v)
    },
    methods: {
        setValue(value) {
            this.selectingValue = value;
            this.textState = value;
            InputBase.methods.setValue.call(this, value);
        },
        onOk() {
            this.setValue(this.selectingValue);
            this.closePanel();
        },
        onTimeChange(value) {
            this.selectingValue = value;
        },
        renderPanel() {
            if (this.panelClosed) {
                return null;
            }
            const style = Object.assign({}, this.panelStyle, {
                left: this.panelLeft + 'px',
                top: this.panelTop + 'px'
            })
            return (
                <div ref="panelRef"
                    class="panel-body panel-body-noheader combo-panel combo-p f-column"
                    style={style}>
                    {this.renderContent()}
                </div>
            )
        },
        renderContent() {
            return (
                <>
                    <TimePanel value={this.valueState} ampm={this.ampm} onChange={this.onTimeChange}></TimePanel>
                    <div class="datebox-button f-row">
                        <a href="javascript:;" class="datebox-button-a f-full" onClick={this.onOk}>{this.okText}</a>
                        <a href="javascript:;" class="datebox-button-a f-full" onClick={this.closePanel}>{this.closeText}</a>
                    </div>
                </>
            )
        }
    }
}