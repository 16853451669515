﻿export default {
    name: 'TreeNodeTitle',
    props: {
        tree: Object,
        node: Object
    },
    render() {
        let cell = this.node.text;
        if (this.tree.$slots.default){
            cell=this.tree.$slots.default({
                node:this.node
            })
        }
        return (
            <span class="tree-title">{cell}</span>
        )
    }
}