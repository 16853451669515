﻿import { createVNode, render } from 'vue';
import domHelper from '../base/DomHelper';
import TooltipContent from './TooltipContent';

let getOptions = (el, value) => {
    let opts = typeof value == 'object'
        ? Object.assign({}, value)
        : { content: String(value) };
    return Object.assign({
        showEvent: 'mouseenter',
        hideEvent: 'mouseleave',
        target: el
    }, opts);
};

let bindEvents = (el, showEvent = '', hideEvent = '') => {
    let bind = (key, eventName, handler) => {
        if (el[key]) {
            if (el[key] != eventName) {
                el[key].split(' ').forEach(event => {
                    domHelper.unbind(el, event, handler);
                });
                el[key] = eventName;
                el[key].split(' ').forEach(event => {
                    domHelper.bind(el, event, handler);
                });
            }
        } else {
            el[key] = eventName;
            el[key].split(' ').forEach(event => {
                domHelper.bind(el, event, handler);
            });
        }
    }
    bind('_showEvent', showEvent, el._activeHandler);
    bind('_hideEvent', hideEvent, el._deactiveHandler);
};

window.TooltipIdIndex = window.TooltipIdIndex || 1;

export default {
    name: 'Tooltip',
    mounted(el, binding) {
        el._activeHandler = function () {
            if (!el._tip) {
                const Tip = createVNode(TooltipContent, {
                    ...el._opts,
                    store: el._opts.store,
                    tooltipIdIndex: window.TooltipIdIndex++
                });
                el._tip = Tip;
                render(Tip, document.querySelector('body'))
            } else {
                Object.assign(el._tip.props, el._opts);
                window.EventHub.$emit('tooltipUpdate', el._tip.props)
            }
            window.EventHub.$emit('tooltipShow', el._tip.props)
        };
        el._deactiveHandler = function () {
            if (el._tip) {
                window.EventHub.$emit('tooltipHide', el._tip.props)
                el._tip = null;
            }
        }
        el._opts = getOptions(el, binding.value);
        bindEvents(el, el._opts.showEvent, el._opts.hideEvent);
    },
    updated(el, binding) {
        el._opts = getOptions(el, binding.value);
        if (el._tip) {
            Object.assign(el._tip.props, el._opts);
            window.EventHub.$emit('tooltipUpdate', el._tip.props)
        }
        bindEvents(el, el._opts.showEvent, el._opts.hideEvent);
    },
    beforeUnmount(el) {
        if (el._tip) {
            el._tip = null;
        }
        bindEvents(el, '', '');
    }
}
