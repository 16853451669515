﻿import domHelper from '../base/DomHelper';
import CollapsedPanel from './CollapsedPanel';

export default {
    name: 'Layout',
    components: {
        CollapsedPanel,
    },
    props: {
        layoutCls: String,
        layoutStyle: Object
    },
    data() {
        return {
            panels: [],
            paddings: null,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0
        }
    },
    computed: {
        layoutClasses() {
            return ['layout', this.layoutCls];
        },
        layoutStyles() {
            return [this.layoutStyle, { padding: this.paddings }];
        }
    },
    watch: {
        panels() {
            this.updatePaddings();
            this.$nextTick(() => this.updatePaddings());
        }
    },
    created() {
        if (window.EventHub) {
            window.EventHub.$on('tabSelect', (tab) => {
                if (domHelper.isChild(this.$el, tab.$el)) {
                    this.updatePaddings();
                }
            });
            window.EventHub.$on('panelSelect', (panel) => {
                if (domHelper.isChild(this.$el, panel.$el)) {
                    this.updatePaddings();
                }
            });
        }
    },
    methods: {
        addPanel(panel) {
            // this.panels.push(panel);
            const pp = this.panels.slice();
            pp.push(panel);
            this.panels = pp;
        },
        removePanel(panel) {
            const pp = this.panels.slice();
            let index = pp.indexOf(panel);
            if (index >= 0) {
                pp.splice(index, 1);
                this.panels = pp;
            }
        },
        getPanel(region) {
            let pp = this.panels.filter(p => p.region == region);
            return pp.length ? pp[0] : null;
        },
        getPaddingValue(region) {
            let panel = this.getPanel(region);
            if (!panel) {
                return 0;
            }
            let val = 0;
            if (panel.collapsedState) {
                if (panel.expander) {
                    val += panel.collapsedSize - 1;
                }
            } else {
                if (panel.float) {
                    if (panel.expander) {
                        val += panel.collapsedSize - 1;
                    }
                } else {
                    if (region == 'west' || region == 'east') {
                        val = domHelper.outerWidth(panel.$el);
                    } else {
                        val = domHelper.outerHeight(panel.$el);
                    }
                    if (!panel.split && panel.border) {
                        val -= 1;
                    }
                }
            }
            return val;
        },
        updatePaddings() {
            this.paddingLeft = this.getPaddingValue('west');
            this.paddingRight = this.getPaddingValue('east');
            this.paddingTop = this.getPaddingValue('north');
            this.paddingBottom = this.getPaddingValue('south');
            this.paddings = [this.paddingTop, this.paddingRight, this.paddingBottom, this.paddingLeft].map(v => v + 'px').join(' ');
            this.$emit('resize')
        },
        onClick(event) {
            let cp = domHelper.closest(event.target, '.layout-panel');
            this.panels.filter(p => p.$el != cp).forEach(p => {
                if (p.isExpanding) {
                    return;
                }
                if (p.float && !p.collapsedState) {
                    p.collapse();
                }
            });
        },
        collapseTitle(region) {
            if (region == 'west' || region == 'east') {
                return ' ';
            }
            const panel = this.getPanel(region);
            return panel ? panel.title : null;
        },
        collapseIconCls(region) {
            const iconMap = {
                'east': 'left',
                'west': 'right',
                'north': 'down',
                'south': 'up'
            };
            return 'layout-button-' + iconMap[region];
        },
        collapsedSize(region) {
            const panel = this.getPanel(region);
            return panel ? panel.collapsedSize : null;
        }

    },
    render() {
        return (
            <div ref="layoutRef" class={this.layoutClasses} style={this.layoutStyles} onClick={this.onClick}>
                {this.$slots.default()}
                {
                    ['west', 'east', 'north', 'south'].map(region => (

                        <CollapsedPanel
                            bodyCls="f-column f-vcenter"
                            key={region}
                            region={region}
                            title={this.collapseTitle(region)}
                            collapsible={true}
                            collapseIconCls={this.collapseIconCls(region)}
                        >
                            {
                                (region == 'west' || region == 'east') &&
                                <div class="f-vtitle f-full">{this.getPanel(region) ? this.getPanel(region).title : null}</div>
                            }
                        </CollapsedPanel>
                    ))
                }
            </div>
        )
    }
}