﻿import InputBase from './InputBase';

export default {
    name: 'SpinnerBase',
    extends: InputBase,
    props: {
        reversed: {
            type: Boolean,
            default: false
        },
        spinners: {
            type: Boolean,
            default: true
        },
        spinAlign: {
            type: String,
            default: 'right'
        },
    },
    methods: {
        onClickUp() {
            if (this.disabled || this.readonly) {
                return;
            }
            if (this.spinAlign == 'left' || this.spinAlign == 'right') {
                this.doSpinUp();
            } else {
                this.reversed ? this.doSpinDown() : this.doSpinUp();
            }
        },
        onClickDown() {
            if (this.disabled || this.readonly) {
                return;
            }
            if (this.spinAlign == 'left' || this.spinAlign == 'right') {
                this.doSpinDown();
            } else {
                this.reversed ? this.doSpinUp() : this.doSpinDown();
            }
        },
        doSpinUp() { },
        doSpinDown() { },
        renderHorizontal() {
            let upcls = 'spinner-button';
            let downcls = 'spinner-button';
            if (this.reversed) {
                upcls += ' spinner-button-up';
                downcls += ' spinner-button-down';
            } else {
                upcls += ' spinner-button-down';
                downcls += ' spinner-button-up';
            }
            return (
                <>
                    <span class="textbox-addon spinner-arrow spinner-button-left f-inline-row f-noshrink f-order1" onClick={this.onClickDown}>
                        <span class={upcls}></span>
                    </span>
                    <span class="textbox-addon spinner-arrow spinner-button-right f-inline-row f-noshrink f-order5" onClick={this.onClickUp}>
                        <span class={downcls}></span>
                    </span>
                </>
            )
        },
        renderVertical() {
            let upcls = 'spinner-button';
            let downcls = 'spinner-button';
            if (this.reversed) {
                upcls += ' spinner-button-up';
                downcls += ' spinner-button-down';
            } else {
                upcls += ' spinner-button-down';
                downcls += ' spinner-button-up';
            }
            return (
                <>
                    <span class="textbox-addon spinner-arrow spinner-button-bottom f-noshrink" onClick={this.onClickDown}>
                        <span class={upcls}></span>
                    </span>
                    <span class="textbox-addon spinner-arrow spinner-button-top f-noshrink" onClick={this.onClickUp}>
                        <span class={downcls}></span>
                    </span>
                </>
            )
        },
        renderDefault() {
            let cls = 'textbox-addon spinner-button-updown f-column f-noshrink';
            if (this.spinAlign == 'left') {
                cls += ' f-order1';
            } else if (this.spinAlign == 'right') {
                cls += ' f-order5';
            }
            return (
                <>
                    <span class={cls}>
                        <span class="spinner-arrow spinner-button-top f-full" onClick={this.onClickUp}>
                            <span class="spinner-arrow-up"></span>
                        </span>
                        <span class="spinner-arrow spinner-button-bottom f-full" onClick={this.onClickDown}>
                            <span class="spinner-arrow-down"></span>
                        </span>
                    </span>
                </>
            )
        },
        renderOthers() {
            if (!this.spinners) {
                return null;
            }
            if (this.spinAlign === 'horizontal') {
                return this.renderHorizontal();
            } else if (this.spinAlign === 'vertical') {
                return this.renderVertical();
            } else {
                return this.renderDefault();
            }
        }
    }
}