﻿export default {
    name: 'GridEmpty',
    props: {
        grid: Object
    },
    render() {
        let content = null;
        if (this.grid.$slots['empty']) {
            content = this.grid.$slots['empty']()
        }
        if (content) {
            return (
                <div class="datagrid-empty">{content}</div>
            )
        } else {
            return null;
        }
    }
}