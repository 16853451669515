﻿import TimeClock from './TimeClock';

export default {
    name: 'TimePanel',
    props: {
        value: String,
        ampm: {
            type: Array,
            default: () => ['am', 'pm']
        }
    },
    components: {
        TimeClock
    },
    data() {
        return {
            selectingType: 'hour',
            selectingAmpm: 'am',
            hour: 0,
            minute: 0
        }
    },
    watch: {
        value() {
            this.setValue(this.value)
        }
    },
    mounted() {
        this.selectingAmpm = this.ampm[0];
        this.setValue(this.value);
    },
    methods: {
        setValue(value) {
            if (value) {
                const parts = value.split(' ');
                const hm = parts[0].split(':');
                this.hour = parseInt(hm[0], 10);
                this.minute = parseInt(hm[1], 10);
                this.selectingAmpm = parts[1];
            }
        },
        getValue() {
            let value = this.getHourStr() + ':' + this.getMinuteStr();
            if (!this.$parent.hour24) {
                value += ' ' + this.selectingAmpm;
            }
            return value;
        },
        getHourStr() {
            const hour = this.hour;
            return hour < 10 ? '0' + hour : hour
        },
        getMinuteStr() {
            const minute = this.minute;
            return minute < 10 ? '0' + minute : minute
        },
        onAmpmClick(ampm) {
            this.selectingAmpm = ampm;
            this.$emit('change', this.getValue());
        },
        onClockSelect(value) {
            const selectingType = this.selectingType;
            if (selectingType === 'hour') {
                this.hour = value;
                this.$nextTick(() => {
                    this.selectingType = 'minute';
                })
            } else {
                this.minute = value;
            }
            this.$emit('change', this.getValue());
        }
    },
    render() {
        return (
            <div class="timepicker-panel f-column f-full">
                <div class="panel-header f-noshrink f-row f-content-center">
                    <div class={['title', { 'title-selected': this.selectingType == 'hour' }]} onClick={() => this.selectingType = 'hour'}>{this.getHourStr()}</div>
                    <div class="sep">:</div>
                    <div class={['title', { 'title-selected': this.selectingType == 'minute' }]} onClick={() => this.selectingType = 'minute'}>{this.getMinuteStr()}</div>
                    {
                        !this.$parent.hour24 &&
                        <div class="ampm f-column">
                            <div class={['title', { 'title-selected': this.selectingAmpm == this.ampm[0] }]} onClick={() => this.onAmpmClick(this.ampm[0])}>{this.ampm[0]}</div>
                            <div class={['title', { 'title-selected': this.selectingAmpm == this.ampm[1] }]} onClick={() => this.onAmpmClick(this.ampm[1])}>{this.ampm[1]}</div>
                        </div>
                    }
                </div>
                <TimeClock
                    value={this.selectingType == 'hour' ? this.hour : this.minute}
                    type={this.selectingType}
                    onSelect={this.onClockSelect}
                />
            </div>
        )
    }
}