﻿import domHelper from '../base/DomHelper';
import GridHeaderCell from './GridHeaderCell';
import GridFilterRow from './GridFilterRow';
import Resizable from '../resizable/Resizable';

export default {
    name: 'GridHeader',
    components: {
        GridHeaderCell,
        GridFilterRow
    },
    directives: {
        Resizable
    },
    props: {
        columns: {
            type: Array,
            default: () => []
        },
        columnGroup: Object,
        paddingWidth: {
            type: Number,
            default: 0
        },
        filterable: {
            type: Boolean,
            default: false
        },
        grid: Object
    },
    data() {
        return {
            heightState: 0,
            scrollLeftState: 0,
            hoverColumn: null,
            dragScope: {}
        }
    },
    computed: {
        filterOnTop() {
            if (this.grid.filterable) {
                if (this.grid.filterPosition == 'both' || this.grid.filterPosition == 'top') {
                    return true;
                }
            }
            return false;
        },
        filterOnBottom() {
            if (this.grid.filterable) {
                if (this.grid.filterPosition == 'both' || this.grid.filterPosition == 'bottom') {
                    return true;
                }
            }
            return false;
        }
    },
    methods: {
        height(value) {
            if (value == undefined) {
                return domHelper.outerHeight(this.$refs.contentRef);
            } else {
                this.heightState = value ? value - 1 : value;
            }
        },
        scrollLeft(value) {
            if (value == undefined) {
                return this.scrollLeftState;
            } else {
                this.scrollLeftState = value;
                this.$refs.headerRef.scrollLeft = value;
            }
        },
        onCellClick(event, col) {
            this.$emit('cellClick', {
                column: col,
                originalEvent: event
            });
        },
        getResizableOpts(col) {
            return {
                disabled: !this.grid.columnResizing || !col.field,
                handles: 'e',
                resizing: (event) => { this.onColumnResizing(col, event) },
                resizeStop: (event) => { this.onColumnResizeStop(col, event) }
            }
        },
        getDraggableOpts(col) {
            return {
                disabled: !this.grid.columnMoving || !col.field,
                revert: true,
                deltaX: 0,
                deltaY: 0,
                edge: 5,
                scope: this.dragScope,
                proxy: this.$refs.proxy,
                dragStart: (event) => { this.onColumnDragStart(col, event) }
            }
        },
        getDroppableOpts(col) {
            return {
                field: col.field,
                dragOver: (scope) => { this.onColumnDragOver(col, scope) },
                dragLeave: (scope) => { this.onColumnDragLeave(col, scope) },
                drop: (scope) => { this.onColumnDrop(col, scope) }
            }
        },
        onColumnResizing(col, event) {
            event.target.style.width = null;
            event.target.style.left = null;
            event.target.style.top = null;
            this.grid.resizeColumn(col.field, event.width);
        },
        onColumnResizeStop(col, event) {
            event.target.style.width = null;
            event.target.style.left = null;
            event.target.style.top = null;
            this.grid.resizeColumn(col.field, event.width);
        },
        onColumnDragStart(col, event) {
            Object.assign(this.dragScope, {
                column: col,
                event: event,
                fromIndex: this.grid.allColumns.indexOf(col),
                viewOffset: domHelper.offset(this.grid.$refs.viewRef)
            })
        },
        onColumnDragOver(col, scope) {
            if (!scope) {
                return;
            }
            scope.toIndex = this.grid.allColumns.indexOf(col);
            const diff = scope.fromIndex - scope.toIndex;
            scope.point = diff === 0 ? null : (diff < 0 ? 'after' : 'before');
            let splitStyle = null;
            if (diff !== 0) {
                let el = scope.event.target.currDroppable.$el;
                let offset = domHelper.offset(el);
                let left = offset.left - scope.viewOffset.left;
                if (scope.toIndex !== 0) {
                    left -= 1;
                }
                splitStyle = {
                    left: domHelper.toStyleValue(diff < 0 ? left + domHelper.outerWidth(el) : left),
                    top: 0
                };
            }
            this.grid.splitStyle = splitStyle
        },
        onColumnDragLeave() {
            this.grid.splitStyle = null;
        },
        onColumnDrop(col, scope) {
            if (!scope) {
                return;
            }
            this.dragScope = {};
            this.$nextTick(() => {
                if (scope.point) {
                    this.grid.moveColumn(scope.column.field, col.field, scope.point)
                }
            })
        },
        renderGroupCells() {
            if (!this.columnGroup) {
                return null;
            }
            return (
                <tbody>
                    {this.filterOnTop && <GridFilterRow columns={this.columns} grid={this.grid}></GridFilterRow>}
                    {
                        this.columnGroup.rows.map(row => (
                            <tr class="datagrid-header-row">
                                {
                                    row.columns.map(col => (
                                        <td
                                            v-Resizable={this.getResizableOpts(col)}
                                            rowspan={col.rowspan}
                                            colspan={col.colspan}
                                            class={(col.field ? 'datagrid-field-td' : '') + (this.hoverColumn == col && col.sortable ? ' datagrid-header-over' : '')}
                                            onMouseenter={()=>this.hoverColumn = col}
                                            onMouseleave={()=>this.hoverColumn = null}
                                            onClick={event => this.onCellClick(event, col)}>
                                            <GridHeaderCell column={col}></GridHeaderCell>
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                    {this.filterOnBottom && <GridFilterRow columns={this.columns} grid={this.grid}></GridFilterRow>}
                </tbody>
            )
        },
        renderRowCells() {
            if (this.columnGroup) {
                return null;
            }
            return (
                <tbody>
                    {this.filterOnTop && <GridFilterRow columns={this.columns} grid={this.grid}></GridFilterRow>}
                    <tr class="datagrid-header-row">
                        {
                            this.columns.map(col => (
                                <td
                                    v-Resizable={this.getResizableOpts(col)}
                                    v-Draggable={this.getDraggableOpts(col)}
                                    v-Droppable={this.getDroppableOpts(col)}
                                    class={'datagrid-field-td' + (this.hoverColumn == col && col.sortable ? ' datagrid-header-over' : '')}
                                    onMouseenter={() => this.hoverColumn = col}
                                    onMouseleave={() => this.hoverColumn = null}
                                    onClick={event => this.onCellClick(event, col)}>
                                    <GridHeaderCell column={col}></GridHeaderCell>
                                </td>
                            ))
                        }
                    </tr>
                    {this.filterOnBottom && <GridFilterRow columns={this.columns} grid={this.grid}></GridFilterRow>}
                </tbody>
            )
        }
    },
    render() {
        return (
            <div class="datagrid-header f-row f-noshrink">
                <div ref="headerRef" class="datagrid-header-inner f-full" style={{ height: this.heightState + 'px' }}>
                    <table ref="contentRef" class="datagrid-htable" border="0" cellspacing="0" cellpadding="0">
                        <colgroup>
                            {
                                this.columns.map(col => (
                                    <col style={{ width: col.widthState }}></col>
                                ))
                            }
                        </colgroup>
                        {this.renderGroupCells()}
                        {this.renderRowCells()}

                    </table>
                </div >
                {this.paddingWidth && <div class="datagrid-header f-noshrink" style={{ width: this.paddingWidth + 'px' }}></div>}
                <DraggableProxy ref="proxy">
                    <div class="datagrid-moving-proxy">{this.dragScope.column ? this.dragScope.column.title : null}</div>
                </DraggableProxy>
            </div >
        )
    }
}