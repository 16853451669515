﻿import LinkButton from '../linkbutton/LinkButton';
import MenuButton from '../menubutton/MenuButton';

export default {
    name: 'SplitButton',
    extends: MenuButton,
    computed: {
        innerCls() {
            let cls = MenuButton.computed.innerCls.call(this);
            cls += ' s-btn s-btn-' + this.size;
            if (this.menu && !this.menu.closed) {
                cls += this.plain ? ' s-btn-plain-active' : ' s-btn-active';
            }
            return cls;
        }
    },
    methods: {
        onClick(event) {
            LinkButton.methods.onClick.call(this, event);
        },
        renderInner() {
            let content = '';
            if (this.isEmpty) {
                content = '';
            } else if (this.text) {
                content = this.text;
            } else if (this.$slots.default) {
                content = this.$slots.default();
            }
            return (
                <>
                    <span class={this.isEmpty ? 'l-btn-text l-btn-empty' : 'l-btn-text'}>
                        {content}
                    </span>
                    <span class={this.btnIconCls}></span>
                    <span class="m-btn-downarrow"></span>
                    <span class="m-btn-line"
                        onClick={this.showMenu}
                        onMouseenter={this.onMouseEnter}
                        onMouseleave={this.onMouseLeave}
                    />
                    {this.$slots.default()}
                </>
            )
        }
    },
    render() {
        return (
            <a ref="btnRef"
                href={this.href || '#'}
                class={this.innerCls}
                style={Object.assign({}, this.$attrs.style, this.btnStyle)}
                onClick={this.onClick}
                onFocus={this.focus}
                onBlur={this.blur}
            >
                <span class={this.btnLeftCls}>
                    {this.renderInner()}
                </span >
                {this.renderOthers()}
            </a >
        )
    }
}