﻿import { defineComponent } from 'vue';
import FieldBase from './FieldBase';

export default defineComponent({
    name: 'InputBase',
    extends: FieldBase,
    components: { FieldBase },
    props: {
        value: [String, Number, Array],
        modelValue: [String, Number, Array],
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        },
        iconCls: String,
        iconAlign: {
            type: String,
            default: 'right'
        },
        placeholder: String,
        multiline: {
            type: Boolean,
            default: false
        },
        invalid: {
            type: Boolean,
            default: false
        },
        tabindex: Number,
        cls: String,
        inputCls: String,
        inputStyle: Object,
        inputId: String,
        textFormatter: Function
        // textFormatter: {
        //     type: Function,
        //     default: (value) => {return value == null ? value : String(value)}
        // }
    },
    data() {
        const v = this.value !== undefined ? this.value : this.modelValue;
        return {
            valueState: v,
            textState: String(v || ''),
            focused: false
        }
    },
    computed: {
        baseClasses() {
            return ['textbox f-inline-row f-field', this.cls, {
                'textbox-disabled': this.disabled,
                'textbox-readonly': this.readonly,
                'textbox-focused': this.focused,
                'textbox-invalid': this.invalidState
            }];
        },
        inputClasses() {
            return ['textbox-text f-full f-order3', this.inputCls, {
                'validatebox-invalid': this.invalidState
            }];
        },
        addonClasses() {
            return ['textbox-addon textbox-addon-icon f-inline-row f-noshrink', {
                'f-order1': this.iconAlign == 'left',
                'f-order5': this.iconAlign == 'right'
            }];
        },
        addonIconClasses() {
            return ['textbox-icon textbox-icon-disabled', this.iconCls];
        },
        text() {
            return this.textState;
        }
    },
    watch: {
        value() {
            this.setValue(this.value);
        },
        modelValue() {
            this.setValue(this.modelValue);
        }
    },
    methods: {
        defaultTextFormatter(value) {
            return value == null ? value : String(value);
        },
        setValue(value) {
            if (value !== this.valueState) {
                let previousValue = this.valueState;
                this.valueState = value;
                this.$emit('update:modelValue', this.valueState);
                this.$emit('valueChange', {
                    currentValue: this.valueState,
                    previousValue: previousValue
                });
                this.afterValueChange();
            }
        },
        onInput(event) {
            this.textState = event.target.value;
        },
        focus() {
            if (this.$refs.inputRef) {
                this.$refs.inputRef.focus();
            }
            this.focused = true;
            this.$emit('focus');
            this.afterFocus();
        },
        blur() {
            if (this.$refs.inputRef) {
                this.$refs.inputRef.blur();
            }
            this.focused = false;
            this.$emit('blur');
            this.afterBlur();
        },
        getSelectionStart() {
            return this.getSelectionRange().start;
        },
        getSelectionRange() {
            let start = 0;
            let end = 0;
            let target = this.$refs.inputRef;
            if (typeof target.selectionStart == 'number') {
                start = target.selectionStart;
                end = target.selectionEnd;
            }
            return { start: start, end: end };
        },
        setSelectionRange(start, end) {
            let target = this.$refs.inputRef;
            if (target.setSelectionRange) {
                target.setSelectionRange(start, end);
            } else if (target.createTextRange) {
                var range = target.createTextRange();
                range.collapse();
                range.moveEnd('character', end);
                range.moveStart('character', start);
                range.select();
            }
        },
        renderInput() {
            const pp = {
                class: this.inputClasses,
                style: this.inputStyle,
                value: this.text,
                id: this.inputId,
                disabled: this.disabled ? 'disabled' : null,
                readonly: (this.readonly || !this.editable) ? 'readonly' : null,
                tabindex: this.tabindex,
                placeholder: this.placeholder,
                onInput: this.onInput,
                onFocus: this.focus,
                onBlur: this.blur
            }
            return (
                <>
                    {!this.multiline && <input ref="inputRef" autocomplete="off" {...pp}></input>}
                    {this.multiline && <textarea ref="inputRef" autocomplete="off" {...pp}></textarea>}
                    <input class="textbox-value" type="hidden" value={this.valueState} disabled={this.disabled ? 'disabled' : null}></input>
                </>
            )
        },
        renderAddon() {
            return (
                <>
                    {this.$slots.default && this.$slots.default()}
                    {
                        this.iconCls &&
                        <span ref="addonRef" class={this.addonClasses}>
                            <span class={this.addonIconClasses}></span>
                        </span>
                    }
                </>
            )
        },
        renderOthers() {
            return null;
        },
        renderField() {
            return (
                <span class={this.baseClasses}>
                    {this.renderInput()}
                    {this.renderAddon()}
                    {this.renderOthers()}
                </span>
            )
        }

    }
})