﻿
export default {
    name: 'GridFooterCell',
    props: {
        row: Object,
        column: Object,
        rowIndex: Number
    },
    render() {
        let cell = null;
        if (this.column.$slots['footer']) {
            cell = this.column.$slots['footer']({
                row: this.row,
                column: this.column,
                rowIndex: this.rowIndex
            });
        } else {
            cell = this.row[this.column.field];
        }
        return (
            <div class="datagrid-cell" style={{ textAlign: this.column.align || null }}>{cell}</div>
        )
    }
}