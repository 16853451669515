﻿import GridView from '../gridbase/GridView';
import DataGridBody from './DataGridBody';

export default {
    name: 'DataGridView',
    extends: GridView,
    components: {
        DataGridBody
    },
    computed: {
        grid() {
            return this.$parent;
        }
    },
    methods: {
        onHeaderCellClick(event) {
            let col = event.column;
            if (col.sortable) {
                this.grid.addSort(event.column);
                this.grid.setData(this.grid.innerData);
                if (this.$refs.body.vscroll) {
                    this.$refs.body.vscroll.refresh();
                }
                this.grid.$emit('sortChange', this.grid.sortsState);
            }
        }

    },
    render() {
        return (
            <div class={this.viewCls}>
                {
                    this.grid.showHeader &&
                    <GridHeader ref="header"
                        columnGroup={this.columnGroup}
                        columns={this.columns}
                        paddingWidth={this.headerPaddingWidth}
                        grid={this.grid}
                        onCellClick={this.onHeaderCellClick}>
                    </GridHeader>
                }
                <DataGridBody ref="body" align="center"
                    columns={this.columns}
                    rows={this.rows}
                    onBodyScroll={this.onBodyScroll}>
                </DataGridBody>
                {
                    this.grid.showFooter &&
                    <GridFooter ref="footer"
                        columns={this.columns}
                        rows={this.footerRows}
                        paddingWidth={this.headerPaddingWidth}>
                    </GridFooter>
                }
            </div>
        )
    }
}