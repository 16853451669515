﻿import { Accordion, AccordionPanel } from '../accordion';
import Tree from '../tree/Tree';
import SideMenuIcon from './SideMenuIcon';

export default {
    name: 'SideMenuItems',
    components: {
        Accordion,
        AccordionPanel,
        Tree,
        SideMenuIcon
    },
    props: {
        sidemenu: Object,
        tip: Boolean,
        data: [Array, Object],
        width: null
    },
    data() {
        return {
            innerData: this.data
        }
    },
    computed: {
        border() {
            return this.tip ? true : this.sidemenu.border;
        },
        multiple() {
            return this.tip ? false : this.sidemenu.multiple;
        }
    },
    watch: {
        data() {
            this.setData(this.data);
        }
    },
    created() {
        this.setData(this.data);
    },
    methods: {
        setData(value) {
            if (this.tip) {
                let v = Object.assign({}, value);
                v.state = 'open';
                this.innerData = [v];
            } else {
                this.innerData = value;
            }
        },
        onPanelSelect(panel) {
            let index = this.$refs.accordion.getPanelIndex(panel);
            if (index >= 0) {
                this.data[index].state = 'open';
                // this.$set(this.data[index], 'state', 'open');
            }
        },
        onPanelUnselect(panel) {
            let index = this.$refs.accordion.getPanelIndex(panel);
            if (index >= 0) {
                this.data[index].state = 'closed';
                // this.$set(this.data[index], 'state', 'closed');
            }
        }
    },
    render() {
        return (
            <div class="sidemenu f-column f-full" style={{ width: this.width ? this.width + 'px' : null }}>
                <Accordion ref="accordion" class="f-full"
                    border={this.border}
                    animate={this.sidemenu.animate}
                    multiple={this.multiple}
                    onPanelSelect={this.onPanelSelect}
                    onPanelUnselect={this.onPanelUnselect}
                >
                    {
                        this.innerData.map(menu => (
                            <AccordionPanel
                                title={menu.text}
                                iconCls={this.tip ? null : menu.iconCls}
                                collapsed={menu.state == 'closed'}>
                                <Tree
                                    data={menu.children}
                                    selection={this.sidemenu.selectionState}
                                    selectLeafOnly={true}
                                    animate={this.sidemenu.animate}
                                    onSelectionChange={this.sidemenu.onSelectionChange}
                                    onNodeClick={this.sidemenu.onNodeClick}>
                                    {

                                    }
                                    {/* <template :slot="sidemenu.$scopedSlots.icon?'icon':null" slot-scope="{node}">
                    <SideMenuIcon :sidemenu="sidemenu" :menu="node" :header="false"></SideMenuIcon>
                </template> */}
                                </Tree>
                                {
                                    !this.tip && this.sidemenu.$slots['icon'] &&
                                    <SideMenuIcon sidemenu={this.sidemenu} menu={menu} header={true}></SideMenuIcon>
                                }
                            </AccordionPanel>

                        ))
                    }

                </Accordion>
            </div>
        )
    }
}