﻿import FieldBase from '../base/FieldBase';

export default {
    name: 'SwitchButton',
    extends: FieldBase,
    components: { FieldBase },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: Boolean,
            default: false
        },
        onText: {
            type: String,
            default: 'ON'
        },
        offText: {
            type: String,
            default: 'OFF'
        },
        handleText: String,
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        inputId: String
    },
    computed: {
        buttonClasses() {
            return ['switchbutton f-inline-row', {
                'switchbutton-readonly': this.readonly,
                'switchbutton-disabled': this.disabled,
                'switchbutton-checked': this.valueState
            }];
        }
    },
    data() {
        return {
            valueState: this.modelValue
        }
    },
    watch: {
        value() {
            if (this.value !== this.valueState) {
                this.valueState = this.value;
            }
        },
        modelValue() {
            if (this.modelValue !== this.valueState) {
                this.valueState = this.modelValue;
            }
        }
    },
    methods: {
        onClick(event) {
            event.stopPropagation();
            if (this.disabled || this.readonly) {
                return;
            }
            this.valueState = !this.valueState;
            // this.$emit('input', this.valueState);
            this.$emit('update:modelValue', this.valueState);
        }
    },
    render() {
        return (
            <span class={this.buttonClasses} onClick={this.onClick}>
                <span class="switchbutton-inner">
                    <span class="switchbutton-on">
                        <span class="f-row f-content-center">{this.onText}</span>
                    </span>
                    <span class="switchbutton-handle">
                        <span class="f-row f-content-center">{this.handleText}</span>
                    </span>
                    <span class="switchbutton-off">
                        <span class="f-row f-content-center">{this.offText}</span>
                    </span>
                    <input class="switchbutton-value" type="checkbox" id={this.inputId} />
                </span>
            </span>
        )
    }
}