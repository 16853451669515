﻿import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ButtonGroup',
    props: {
        selectionMode: {
            type: String,
            default: 'multiple' // or single
        }
    },
    data() {
        return {
            buttons: []
        }
    },
    watch: {
        buttons() {
            this.initButtons();
        }
    },
    methods: {
        addButton(button) {
            const bb = this.buttons.slice();
            bb.push(button);
            this.buttons = bb;
        },
        removeButton(button) {
            const bb = this.buttons.slice();
            let index = bb.indexOf(button);
            if (index >= 0) {
                bb.splice(index, 1);
                this.buttons = bb;
            }
        },
        initButtons() {
            this.buttons.forEach(btn => {
                btn.afterClick = () => {
                    if (this.selectionMode == 'single') {
                        this.buttons.filter((b) => b != btn).forEach((b) => {
                            b.selectedState = false;
                        });
                        btn.selectedState = true;
                    }
                }
            })
        }
    },
    render() {
        return (
            <span class="button-group f-inline-row">
                {this.$slots.default()}
            </span>
        )
    }
})