﻿export default {
    name: 'SideMenuIcon',
    props: {
        sidemenu: Object,
        menu: Object,
        header: {
            type: Boolean,
            default: true
        }
    },
    render(){
        return this.sidemenu.$slots['icon']({
            menu: this.menu,
            header: this.header,
            collapsed: this.sidemenu.collapsed
        })
    }
}