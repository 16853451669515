﻿import DraggableClass from './DraggableClass';

export default {
    name: 'Draggable',
    mounted(el, binding){
        el._dragInstance = new DraggableClass(el, binding.value);
        el._dragInstance.bindEvents();
    },
    updated(el, binding){
        el._dragInstance.updateOptions(binding.value);
    },
    beforeUnmount(el){
        el._dragInstance.unbindEvents();
    }
}