﻿import { createVNode, render } from 'vue';
import MessagerDialog from './MessagerDialog';

window.MessagerIdIndex = window.MessagerIdIndex || 1;
export default class Messager {
    constructor() {
        this.ok = window.Locale.t('Messager.ok', 'Ok');
        this.cancel = window.Locale.t('Messager.cancel', 'Cancel');
        this.template = null;
        this.dialogs = [];
    }

    alert(options) {
        if (!options.buttons || !options.buttons.length) {
            options.buttons = [{ text: this.ok, value: true }];
        }
        return this.openDialog(options, 'alert');
    }

    confirm(options) {
        if (!options.icon) {
            options.icon = 'question';
        }
        if (!options.buttons || !options.buttons.length) {
            options.buttons = [
                { text: this.ok, value: true },
                { text: this.cancel, value: false }
            ];
        }
        return this.openDialog(options, 'confirm');
    }

    prompt(options) {
        if (!options.icon) {
            options.icon = 'question';
        }
        if (!options.buttons || !options.buttons.length) {
            options.buttons = [
                { text: this.ok, value: true },
                { text: this.cancel, value: false }
            ];
        }
        return this.openDialog(options, 'prompt');
    }

    // setPositions() {
    //     const dialogs = this.dialogs.filter(d => d.messagerType == 'tip');
    //     let top = 20 + document.body.scrollTop + document.documentElement.scrollTop;
    //     dialogs.forEach(dlg => {
    //         dlg.top = top;
    //         top += 10 + domHelper.outerHeight(dlg.$refs.panelRef);
    //     })
    // }
    setPositions() {
        const dialogs = this.dialogs.filter(d => d.messagerType == 'tip');
        let top = 20 + document.body.scrollTop + document.documentElement.scrollTop;
        dialogs.forEach(dlg => {
            dlg.top = top;
            window.EventHub.$emit('messagerPosition', dlg)
            top += 10 + dlg.outerHeight;
        })
    }

    tip(options) {
        if (typeof options == 'string') {
            options = { msg: options }
        }
        if (options.timeout == null) {
            options.timeout = 2000;
        }
        let top = 0;
        const dialogs = this.dialogs.filter(d => d.messagerType == 'tip');
        if (dialogs.length) {
            const dlg = dialogs[dialogs.length - 1];
            top = dlg.top + dlg.outerHeight;
            // top = dlg.top + domHelper.outerHeight(dlg.$refs.panelRef);
        }
        const dlg = this.openDialog(Object.assign({
            borderType: 'none',
            dialogCls: 'messager-tip f-animate',
            dialogStyle: { top: top + 'px', height: 'auto' },
            buttons: null,
            modal: false
        }, options), 'tip')
        setTimeout(() => this.setPositions());
        return dlg;
    }

    openDialog(options, type = 'alert') {
        options.messagerType = type;
        options.messagerIdIndex = window.MessagerIdIndex++;

        const createDialog = () => {
            const el = document.createElement('div');
            document.body.appendChild(el);
            const closeMe = (resultValue) => {
                const index = this.dialogs.findIndex(d => d.messagerIdIndex == options.messagerIdIndex)
                if (index>=0){
                    const dlg = this.dialogs[index];
                    this.dialogs.splice(index, 1);
                    window.EventHub.$emit('messagerClose', dlg)
                    setTimeout(() => {
                        render(null, el)
                        document.body.removeChild(el);
                        if (dlg.props.result){
                            dlg.props.result(resultValue)
                        }
                        this.setPositions();
                    })

                }
            }
            const vn = createVNode(MessagerDialog, {
                ...options,
                closeMe,
                onClose: ()=>{
                    closeMe()
                }
            })
            render(vn, el)
            return {
                closeMe: closeMe,
                messagerType: options.messagerType,
                messagerIdIndex: options.messagerIdIndex,
                props: vn.props
            }
        }

        const dialog = createDialog()
        this.dialogs.push(dialog)
        if (options.timeout) {
            setTimeout(() => {
                dialog.closeMe();
            }, options.timeout)
        }
        window.EventHub.$emit('messagerOpen', dialog.props)
    }

    // openDialog(options, type = 'alert') {
    //     options.messagerType = type;
    //     if (options.template || this.template) {
    //         options.component = {
    //             template: options.template || this.template,
    //             extends: MessagerContent
    //         };
    //     }
    //     const MessageDialogConstructor = window.Vue.extend(MessagerDialog);
    //     let dialog = new MessageDialogConstructor({
    //         propsData: options
    //     });
    //     dialog.$mount();
    //     document.body.appendChild(dialog.$el);
    //     dialog.open();
    //     dialog.$on('close', () => {
    //         const index = this.dialogs.indexOf(dialog);
    //         if (index >= 0) {
    //             this.dialogs.splice(index, 1);
    //         }
    //         dialog.$nextTick(() => {
    //             document.body.removeChild(dialog.$el);
    //             dialog.$destroy();
    //             if (options.result) {
    //                 options.result(dialog.resultValue);
    //             }
    //             this.setPositions();
    //         });
    //     });
    //     this.dialogs.push(dialog);
    //     if (options.timeout) {
    //         setTimeout(() => {
    //             dialog.close();
    //         }, options.timeout)
    //     }
    //     return dialog;
    // }

    closeAll() {
        this.dialogs.forEach(d => d.closeMe());
        this.dialogs = []
    }
}