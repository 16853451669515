﻿import GridBody from '../gridbase/GridBody';
import TreeGridChildren from './TreeGridChildren';

export default {
    name: 'TreeGridBody',
    extends: GridBody,
    components: {
        TreeGridChildren
    },
    computed: {
        view() {
            return this.$parent;
        }
    },
    render() {
        return (
            <div ref="bodyRef" class="datagrid-body f-full" onScroll={this.onScroll}>
                <div ref="innerRef" class="datagrid-body-inner">
                    <TreeGridChildren gridBody={this} rows={this.rows} columns={this.columns}></TreeGridChildren>
                </div>
            </div>
        )
    }
}