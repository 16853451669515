﻿import GridFilterCell from './GridFilterCell';

export default {
    name: 'GridFilterRow',
    components: {
        GridFilterCell
    },
    props: {
        columns: Array,
        grid: Object
    },
    methods1: {
        isOnLeft(col) {
            if (col.filterOperators && col.filterOperators.length) {
                if (this.grid.filterBtnPosition == 'left') {
                    return true;
                }
            }
            return false;
        },
        isOnRight(col) {
            if (col.filterOperators && col.filterOperators.length) {
                if (this.grid.filterBtnPosition == 'right') {
                    return true;
                }
            }
            return false;
        }

    },
    render() {
        return (
            <tr class="datagrid-header-row datagrid-filter-row">
                {
                    this.columns.map(col => (
                        <td>
                            <GridFilterCell column={col} grid={this.grid}></GridFilterCell>
                        </td>
                    ))
                }
            </tr>
        )
    }
}