﻿import treeHelper from '../base/TreeHelper';
import SideMenuItems from './SideMenuItems';
import SideMenuIcon from './SideMenuIcon';

export default {
    name: 'SideMenu',
    components: {
        SideMenuItems,
        SideMenuIcon
    },
    props: {
        data: Array,
        selection: Object,
        collapsed: {
            type: Boolean,
            default: false
        },
        border: {
            type: Boolean,
            default: true
        },
        animate: {
            type: Boolean,
            default: true
        },
        multiple: {
            type: Boolean,
            default: true
        },
        floatMenuWidth: {
            type: Number,
            default: 200
        },
        floatMenuPosition: {
            type: String,
            default: 'right'
        }
    },
    data() {
        return {
            // selection: null,
            selectionState: this.selection,
            tipClosed: true,
            innerData: []
        }
    },
    watch: {
        data(value) {
            this.setData(value);
        },
        selection(value) {
            this.selectItem(value);
        }
    },
    computed: {
        sidemenu() {
            return this;
        }
    },
    mounted() {
        this.setData(this.data);
    },
    methods: {
        setData(value) {
            if (value == null) {
                value = [];
            }
            this.innerData = Object.assign([], value);
            treeHelper.$vue = this;
            treeHelper.forNodes(this.innerData, (node) => {
                if (!node.iconCls) {
                    node.iconCls = 'sidemenu-default-icon';
                    // this.$set(node, 'iconCls', 'sidemenu-default-icon');
                }
                if (node.children) {
                    node.nodeCls = 'tree-node-nonleaf';
                    // this.$set(node, 'nodeCls', 'tree-node-nonleaf');
                    if (!node.state) {
                        node.state = 'closed';
                        // this.$set(node, 'state', 'closed');
                    }
                    if (node.state == 'open') {
                        node.nodeCls = 'tree-node-nonleaf';
                        // this.$set(node, 'nodeCls', 'tree-node-nonleaf');
                    } else {
                        node.nodeCls = 'tree-node-nonleaf tree-node-nonleaf-collapsed';
                        // this.$set(node, 'nodeCls', 'tree-node-nonleaf tree-node-nonleaf-collapsed');
                    }
                }
            });
        },
        getTipOpts(menu) {
            return {
                position: this.floatMenuPosition,
                tooltipCls: 'sidemenu-tooltip',
                valign: 'top',
                propsData: {
                    sidemenu: this,
                    data: menu,
                    tip: true,
                    width: this.floatMenuWidth
                },
                component: SideMenuItems,
                content: (
                    <SideMenuItems
                        sidemenu={this}
                        data={menu}
                        tip={true}
                        width={this.floatMenuWidth}
                    />
                ),
                closed: menu.tipClosed != null ? menu.tipClosed : true,
                tooltipShow: () => {
                    menu.tipClosed = false;
                    // this.$set(menu, 'tipClosed', false);
                },
                tooltipHide: () => {
                    menu.tipClosed = true;
                    // this.$set(menu, 'tipClosed', true);
                }
            };
        },
        selectItem(item) {
            if (item != this.selectionState) {
                let parent = null;
                treeHelper.forNodes(this.innerData, (node) => {
                    (node.children || []).forEach(n => {
                        if (n == item) {
                            parent = node;
                        }
                    })
                })
                while (parent) {
                    parent.state = 'open';
                    // this.$set(parent, 'state', 'open');
                    parent = parent.parent;
                }
                this.selectionState = item;
            }
        },
        onSelectionChange(node) {
            this.selectionState = node;
            this.$emit('selectionChange', this.selectionState);
        },
        onNodeClick(node) {
            if (node.children) {
                node.state = node.state == 'closed' ? 'open' : 'closed'
                // this.$set(node, 'state', node.state == 'closed' ? 'open' : 'closed');
                if (node.state == 'open') {
                    node.nodeCls = 'tree-node-nonleaf';
                    // this.$set(node, 'nodeCls', 'tree-node-nonleaf');
                } else {
                    node.nodeCls = 'tree-node-nonleaf tree-node-nonleaf-collapsed';
                    // this.$set(node, 'nodeCls', 'tree-node-nonleaf tree-node-nonleaf-collapsed');
                }
            } else {
                this.$emit('itemClick', node);
                this.innerData.forEach(menu => menu.tipClosed = true);
                // this.innerData.forEach(menu => this.$set(menu, 'tipClosed', true));
            }
        }
    },
    render() {
        return (
            <div class="f-column">
                {
                    this.collapsed &&
                    <div class="sidemenu sidemenu-collapsed f-full">
                        <div class={['accordion', { 'accordion-noborder': !this.border }]}>
                            {
                                this.innerData.map(menu => (
                                    <div class="panel-header accordion-header" v-Tooltip={this.getTipOpts(menu)}>
                                        {
                                            !this.$slots['icon'] &&
                                            <>
                                                <div class="panel-title panel-with-icon"></div>
                                                <div class={['panel-icon', menu.iconCls]}></div>
                                            </>
                                        }
                                        {
                                            this.$slots['icon'] &&
                                            <SideMenuIcon sidemenu={this.sidemenu} menu={menu} header={true}></SideMenuIcon>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
                {
                    !this.collapsed &&
                    <SideMenuItems sidemenu={this.sidemenu} data={this.innerData}>
                    </SideMenuItems>
                }
            </div >
        )
    }
}