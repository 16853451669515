﻿import { LinkButton } from "../linkbutton";

export default {
    name: 'MessagerContent',
    props: {
        messagerType: String,
        title: String,
        icon: String,
        msg: String,
        value: String,
        buttons: Array
    },
    data() {
        return {
            inputValue: this.value
        }
    },
    computed: {
        messagerIcon() {
            return this.icon ? 'messager-' + this.icon : null;
        },
        dialog() {
            return this.$parent;
        }
    },
    mounted() {
        if (this.$refs.input) {
            setTimeout(() => {
                this.$refs.input.focus();
            }, 300);
        } else if (this.$refs.buttons && this.$refs.buttons.length) {
            setTimeout(() => {
                this.$refs.buttons[0].focus();
            }, 300);
        }
    },
    methods: {
        closeDialog(button) {
            if (this.messagerType == 'prompt' && button && button['value'] == true) {
                this.dialog.resultValue = this.inputValue;
            } else {
                this.dialog.resultValue = button ? button['value'] : null;
            }
            this.dialog.close();
        }
    },
    render() {
        return (
            <div class="f-column f-full">
                <div class="messager-body f-full f-column">
                    <div class="f-row f-full">
                        {this.messagerIcon && <div class={'f-noshrink messager-icon ' + this.messagerIcon}></div>}
                        <div class="f-full">
                            {this.msg}
                        </div>
                    </div>
                    {
                        this.messagerType == 'prompt' &&
                        <div style="margin-top:20px">
                            <input ref="input" class="messager-input" v-model={this.inputValue} />
                        </div>
                    }
                </div>
                {
                    this.buttons &&
                    <div class="dialog-button messager-button f-noshrink">
                        {
                            this.buttons.map(button => (
                                <LinkButton
                                    ref="buttons"
                                    text={button.text}
                                    onClick={() => this.closeDialog(button)}
                                />
                            ))
                        }
                    </div>
                }
            </div>
        )
    }
}