﻿import domHelper from '../base/DomHelper';

export default {
    name: 'TimeClock',
    props: {
        value: {
            type: Number,
            default: () => 0
        },
        type: {
            type: String,
            default: () => 'hour'
        }
    },
    computed: {
        hour24() {
            return this.$parent.$parent.hour24;
        },
        hourDistance() {
            return this.$parent.$parent.hourDistance;
        }
    },
    data() {
        return {
            valueState: 0,
            data: [],
            radius: 0,
            width: 0,
            height: 0
        }
    },
    watch: {
        value() {
            this.valueState = this.value;
        },
        type() {
            this.data = this.getData(this.type);
        }
    },
    mounted() {
        setTimeout(() => {
            this.valueState = this.value;
            this.data = this.getData(this.type);
            const width = domHelper.outerWidth(this.$refs.elRef);
            const height = domHelper.outerHeight(this.$refs.elRef);
            const size = Math.min(width, height) - 20;
            this.width = size;
            this.height = size;
            this.radius = size / 2;
        })
    },
    methods: {
        getData(type = 'hour') {
            let data = [];
            if (type == 'hour') {
                for (let i = 0; i < 12; i++) {
                    data.push(String(i))
                }
                data[0] = '12'
            } else {
                for (let i = 0; i < 60; i += 5) {
                    data.push(i < 10 ? '0' + i : String(i))
                }
                data[0] = '00'
            }
            return data;
        },
        getHour24(value) {
            let itemValue = parseInt(value, 10);
            itemValue += 12;
            if (itemValue == 24) {
                itemValue = '00';
            }
            return itemValue;
        },
        itemClasses(value) {
            return ['item f-column f-content-center', {
                'item-selected': value == this.valueState
            }]
        },
        itemStyle(value, is24) {
            const type = this.type;
            const distance = this.hourDistance[1] - this.hourDistance[0];
            const radius = this.radius - (this.hour24 && this.type == 'hour' ? (is24 ? 0 : distance) : 0);
            const angular = parseInt(value, 10) / (type == 'hour' ? 12 : 60) * 360 * Math.PI / 180;
            const x = (radius - 20) * Math.sin(angular);
            const y = -(radius - 20) * Math.cos(angular);
            return {
                transform: `translate(${x}px,${y}px)`,
            }
        },
        clockStyle() {
            return {
                width: this.width + 'px',
                height: this.height + 'px',
                marginLeft: -this.width / 2 + 'px',
                marginTop: -this.height / 2 + 'px'
            }
        },
        handStyle() {
            const value = parseInt(this.valueState, 10);
            const angular = value / (this.type == 'hour' ? 12 : 60) * 360;
            const style = {
                transform: `rotate(${angular}deg)`
            }
            if (this.hour24 && this.type == 'hour') {
                if (value == 0) {
                    style.top = this.hourDistance[0] + 'px';
                } else if (value <= 12) {
                    style.top = this.hourDistance[1] + 'px';
                }
            }
            return style;
        },
        onItemClick(value, event) {
            event.stopPropagation();
            event.preventDefault();
            value = parseInt(value, 10);
            this.valueState = value;
            this.$emit('select', value);
        }
    },
    render() {
        return (
            <div ref="elRef" class="clock-wrap f-full f-column f-content-center">
                <div class="clock" style={this.clockStyle()}>
                    <div class="center"></div>
                    <div class="hand" style={this.handStyle()}>
                        <div className="drag"></div>
                    </div>
                    {
                        this.data.map(value => (
                            <div class={this.itemClasses(value)} style={this.itemStyle(value)} onClick={event => this.onItemClick(value, event)}>{value}</div>
                        ))
                    }
                    {
                        this.data.map(value => (
                            <>
                                {this.hour24 && this.type == 'hour' &&
                                    <div>
                                        <div class={this.itemClasses(this.getHour24(value))} style={this.itemStyle(this.getHour24(value), true)} onClick={event => this.onItemClick(this.getHour24(value), event)}>{this.getHour24(value)}</div>
                                    </div>
                                }
                            </>
                        ))
                    }
                </div>
            </div>
        )
    }
}