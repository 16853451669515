﻿import treeHelper from '../base/TreeHelper';
import Pagination from '../pagination/Pagination';
import GridBase from '../gridbase/GridBase';
import GridEmpty from '../gridbase/GridEmpty';
import TreeGridView from './TreeGridView';

export default {
    name: 'TreeGrid',
    extends: GridBase,
    components: {
        Pagination,
        TreeGridView,
        GridEmpty
    },
    props: {
        idField: String,
        treeField: String,
        selectionMode: {
            type: String,
            default: 'single'
        },
        checkbox: {
            type: Boolean,
            default: false
        },
        cascadeCheck: {
            type: Boolean,
            default: true
        },
        animate: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        treeHelper.$vue = this;
    },
    methods: {
        getCheckedRows(state = 'checked') {
            let rows = [];
            treeHelper.cascadeCheck = this.cascadeCheck;
            treeHelper.forNodes(this.innerData, (row) => {
                if (row.checkState == state) {
                    rows.push(row);
                }
            });
            return rows;
        },
        checkRow(row) {
            treeHelper.cascadeCheck = this.cascadeCheck;
            treeHelper.checkNode(row, () => {
                this.$emit('rowCheck', row);
            });
        },
        uncheckRow(row) {
            treeHelper.cascadeCheck = this.cascadeCheck;
            treeHelper.uncheckNode(row, () => {
                this.$emit('rowUncheck', row);
            });
        },
        uncheckAllRows() {
            treeHelper.uncheckAllNodes(this.innerData, () => {

            });
        },
        adjustCheck(row) {
            treeHelper.cascadeCheck = this.cascadeCheck;
            treeHelper.adjustCheck(row);
        },
        sortData() {
            if (!this.sortsState || !this.sortsState.length) {
                return;
            }
            let cc = [];
            for (let i = 0; i < this.sortsState.length; i++) {
                cc.push(this.findColumn(this.sortsState[i].field));
            }
            let sortFunc = (a, b) => {
                return a == b ? 0 : (a > b ? 1 : -1);
            };
            let _sort = (rows) => {
                rows.sort((r1, r2) => {
                    let r = 0;
                    for (let i = 0; i < this.sortsState.length; i++) {
                        let sort = this.sortsState[i];
                        if (cc[i] && cc[i].sorter) {
                            r = cc[i].sorter(r1, r2);
                        } else {
                            r = sortFunc(r1[sort.field], r2[sort.field]);
                        }
                        r = r * (sort.order == 'asc' ? 1 : -1);
                        if (r != 0) {
                            return r;
                        }
                    }
                    return r;
                });
                rows.forEach(row => {
                    if (row.children && row.children.length) {
                        _sort(row.children);
                    }
                });
            };
            _sort(this.innerData);
        }
    },
    render() {
        return (
            <div class="f-column panel-noscroll">
                <div style="display:none">{this.$slots.default()}</div>
                <div class={['panel-body panel-body-noheader datagrid datagrid-wrap f-full f-column', { 'panel-body-noborder': !this.border }]}>
                    {
                        this.pagination && (this.pagePosition == 'both' || this.pagePosition == 'top') &&
                        <Pagination class="datagrid-pager datagrid-pager-top f-noshrink"
                            total={this.totalState}
                            pageSize={this.pageSizeState}
                            pageNumber={this.pageNumberState}
                            loading={this.loading}
                            onPageChange={this.onPageChange}
                        />
                    }
                    <div ref="viewRef" class="datagrid-view f-row f-full">
                        {
                            (this.leftGroup || this.leftColumns) &&
                            <TreeGridView ref="view1"
                                key="view1"
                                viewIndex={1}
                                columnGroup={this.leftGroup}
                                columns={this.leftColumns}
                                rows={this.rows}
                                footerRows={this.footerRows}
                                style={{ width: this.leftFrozenWidth }}
                            />
                        }
                        <TreeGridView ref="view2"
                            key="view2"
                            viewIndex={2}
                            columnGroup={this.centerGroup}
                            columns={this.centerColumns}
                            rows={this.rows}
                            footerRows={this.footerRows}
                            onBodyScroll={this.onBodyScroll}
                        />
                        {
                            (this.rightGroup || this.rightColumns) &&
                            <TreeGridView ref="view3"
                                key="view3"
                                viewIndex={3}
                                columnGroup={this.rightGroup}
                                columns={this.rightColumns}
                                rows={this.rows}
                                footerRows={this.footerRows}
                                style={{ width: this.rightFrozenWidth }}
                            />
                        }
                        {this.splitStyle && <div class="datagrid-split-proxy" style={this.splitStyle}></div>}
                    </div>
                    {
                        this.pagination && (this.pagePosition == 'both' || this.pagePosition == 'bottom') &&
                        <Pagination class="datagrid-pager f-noshrink"
                            total={this.totalState}
                            pageSize={this.pageSizeState}
                            pageNumber={this.pageNumberState}
                            loading={this.loading}
                            pageChange={this.onPageChange}
                        />
                    }
                </div>
                {
                    this.loading &&
                    <div class="datagrid-loading f-row">
                        <div class="datagrid-mask"></div>
                        <div class="datagrid-mask-msg">{this.loadMsg}</div>
                    </div>
                }
                {
                    !this.rows.length && !this.loading &&
                    <GridEmpty grid={this}></GridEmpty>
                }
            </div>
        )
    }
}